import { Location, useLocation } from "react-router-dom";

// Components
import Filter, { IFilterOption } from "Components/Elements/Filter";
import I18n from "Components/Elements/I18n";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import BasePage from "Components/Pages/Base";

// Styles
import Incidents, { IncidentsListResponse } from "Api/SmartSig/Incidents";
import Loader from "Components/Elements/Loader";
import TicketTable from "Components/Materials/TicketTable";
import classes from "./classes.module.scss";

type IProps = {
	location: Location;
};

type IState = {
	prevNextPaginationButtons: boolean;
	totalPages: number;
	currentPage: number;
	filters: IFilterOption[];
	isPopupOpen: boolean;
	isLoadingExport: boolean;
	isLoading: boolean;
	searchQuery: string;
	ticketData: IncidentsListResponse | null;
};

class Debouncer {
	private timer: number = 0;

	private constructor() {}

	public static new() {
		return new this();
	}

	public debounce(func: () => Promise<void>, timeout: number) {
		window.clearTimeout(this.timer);
		this.timer = window.setTimeout(() => {
			func();
		}, timeout);
	}
}

class TicketClass extends BasePage<IProps, IState> {
	private filters: IFilterOption[] = [
		{
			label: "Open",
			value: "OPEN",
			isChecked: true,
		},
		{
			label: "In progress",
			value: "IN_PROGRESS",
			isChecked: true,
		},
		{
			label: "Closed",
			value: "CLOSED",
			isChecked: true,
		},
		{
			label: "Pending",
			value: "PENDING",
			isChecked: true,
		},
		{
			label: "Canceled",
			value: "CANCELED",
			isChecked: true,
		},
		{
			label: "Resolved",
			value: "RESOLVED",
			isChecked: true,
		},
		{
			label: "Reopened",
			value: "REOPENED",
			isChecked: true,
		},
	];

	private reloadTicketListDebouncer = Debouncer.new();

	public constructor(props: IProps) {
		super(props);

		this.state = {
			prevNextPaginationButtons: false,
			totalPages: 7,
			currentPage: 1,
			filters: this.filters,
			isPopupOpen: false,
			isLoadingExport: false,
			isLoading: true,
			searchQuery: "",
			ticketData: null,
		};

		this.onFilterChange = this.onFilterChange.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
		this.onResize = this.onResize.bind(this);
		this.onSearchInputChange = this.onSearchInputChange.bind(this);
	}

	public override render() {
		return (
			<DefaultTemplate title={I18n.translate("pages.history.page_title")}>
				<div className={classes["root"]}>
					<div className={classes["top-container"]}>
						<h1 className={classes["title"]}>
							<I18n map="pages.ticket.title" />
						</h1>
						{/* <div className={classes["export-button-container"]}>
							<Button
								variant={EButtonVariant.SECONDARY}
								sizing={EButtonSize.SMALL}
								icon={<DownloadIcon />}
								iconPosition={EButtonIconPosition.START}
								onClick={this.onExportToCsv}>
								<I18n map="pages.history.download_csv" />
							</Button>
						</div> */}
					</div>
					<div className={classes["filter-container"]}>
						{/* search bar*/}
						<div className={classes["search-bar"]}>
							<input type="text" placeholder={I18n.translate("pages.ticket.search")} onChange={this.onSearchInputChange} />
						</div>

						<Filter
							className={classes["filter"]}
							label={I18n.translate("pages.history.filter_label")}
							options={this.filters}
							onFilterChange={this.onFilterChange}
						/>
					</div>

					<div className={classes["content-container"]}>
						{this.state.isLoading || !this.state.ticketData ? (
							<div className={classes["loader"]}>
								<Loader />
							</div>
						) : (
							<TicketTable bodyData={this.state.ticketData.results} className={classes["history-table"]} />
						)}
					</div>
				</div>
			</DefaultTemplate>
		);
	}

	public override async componentDidMount() {
		window.addEventListener("resize", this.onResize);
		this.onResize();

		// TODO: Check URL for filters and fetch data from API with the filters
		const urlParams = new URLSearchParams(this.props.location.search);
		const statusFilter = urlParams.get("status");

		if (statusFilter) {
			this.filters.find((filter) => filter.value === statusFilter)!.isChecked = true;
			this.filters.find((filter) => filter.value !== statusFilter)!.isChecked = false;
		}

		this.reloadTicketList();
	}

	public override componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	private async reloadTicketList() {
		this.reloadTicketListDebouncer.debounce(async () => {
			this.setState({ isLoading: true });
			const data = await Incidents.getInstance().get({
				page_size: "10",
				search_term: this.state.searchQuery,
				statusIn: this.getCheckedFilters(),
			});
			this.setState({
				isLoading: false,
				ticketData: data,
			});
		}, 1000);
		this.setState({ isLoading: false });
	}

	private onSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
		this.setState({ searchQuery: event.target.value }, () => {
			this.reloadTicketList();
		});
	}

	private onFilterChange(option: IFilterOption) {
		const newFilters = this.state.filters.map((filter) => (filter.value === option.value ? option : filter));
		this.setState({ filters: newFilters }, () => {
			this.reloadTicketList();
		});
	}

	private onPageChange(page: number) {
		this.setState({ currentPage: page }, () => {
			this.reloadTicketList();
		});
	}

	private onResize() {
		if (window.innerWidth < 600) {
			this.setState({ prevNextPaginationButtons: false });
		} else {
			this.setState({ prevNextPaginationButtons: true });
		}
	}

	private getCheckedFilters(): string[] {
		return this.state.filters.filter((filter) => filter.isChecked).map((filter) => filter.value);
	}
}

export default function Ticket() {
	const location = useLocation();
	return <TicketClass location={location} />;
}
