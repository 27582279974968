import classNames from "classnames";
import { Component } from "react";

// Components
import TicketTableBody from "./TicketTableBody";
import TicketTableHeader from "./TicketTableHeader";

// Entities

// styles
import { IncidentPage } from "Api/SmartSig/Incidents";
import classes from "./classes.module.scss";


type IProps = {
	bodyData: IncidentPage[];
	className?: string;
};
type IState = {};

export default class TicketTable extends Component<IProps, IState> {
	override render() {
		return (
			<div className={classNames(classes["root"], this.props.className)}>
				<table className={classes["table"]}>
					<TicketTableHeader />
					<TicketTableBody data={this.props.bodyData} />
				</table>
			</div>
		);
	}
}
