import { Component } from "react";
import ReactApexChart from "react-apexcharts";

// Components
import { EFrequency } from "Components/Elements/FrequencyPicker";
import Module from "Components/Elements/Module";

// Assets

// Styles
import format from "date-fns/format";
import classes from "./classes.module.scss";

type IProps = {
	frequency: EFrequency;
	title: string;
	detail?: string; // URL to history page with filters if applicable
	data: { currentTotal: number[]; previousTotal: number[]; xaxis: string[] };
	qte: number;
};
type IState = {
	options: ApexCharts.ApexOptions | undefined;
	series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
};

export default class LineChart extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			series: [],
			options: {
				chart: {
					height: 350,
					type: "line",
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [5, 7, 5],
					curve: "smooth",
					dashArray: [8, 0, 5],
				},
				legend: {
					tooltipHoverFormatter: function (val, opts) {
						return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + "";
					},
				},
				markers: {
					size: 0,
					hover: {
						sizeOffset: 6,
					},
				},
				xaxis: {
					categories: this.props.data.xaxis.map((date) => {
						if (date.includes(">")) {
							return format(new Date(date.split(">")[0]!), "dd/MM/yyyy");
						}
						return format(new Date(date), "dd/MM/yyyy");
					}),
				},
				colors: ["#E9AF3E", "#4B4EFC"],
			},
		};
	}

	override render() {
		const detailsURL = `${Module.config.pages.History.props.path}?${this.props.detail}`;
		return (
			<div className={classes["root"]}>
				<div className={classes["header"]}>
					<div className={classes["title-and-qte"]}>
						<p className={classes["title"]}>{this.props.title}</p>
						<p className={classes["qte"]}>{this.props.qte}</p>
					</div>

					{/* {this.props.detail && (
						<NavLink to={detailsURL} className={classes["detail"]}>
							<I18n map={"line_chart.details"} /> <ArrowRight className={classes["arrow-icon"]} />
						</NavLink>
					)} */}
				</div>

				<ReactApexChart options={this.state.options} series={this.state.series} type="line" height={300} />
			</div>
		);
	}

	override componentDidMount() {
		this.setChartsOptions();
	}

	override componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (prevProps.frequency === this.props.frequency && prevProps.data === this.props.data) return;

		this.setChartsOptions();
	}

	private setChartsOptions() {
		this.setState({
			series: [
				{
					name: "Cumulated amount",
					data: this.props.data.previousTotal,
				},
				{
					name: "Current amount",
					data: this.props.data.currentTotal,
				},
			],
			options: {
				chart: {
					height: 350,
					type: "line",
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [5, 7, 5],
					curve: "smooth",
					dashArray: [8, 0, 5],
				},
				legend: {
					tooltipHoverFormatter: function (val, opts) {
						return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + "";
					},
				},
				markers: {
					size: 0,
					hover: {
						sizeOffset: 6,
					},
				},
				xaxis: {
					categories: this.props.data.xaxis.map((date) => {
						if (date.includes(">")) {
							return format(new Date(date.split(">")[0]!), "dd/MM/yyyy");
						}
						return format(new Date(date), "dd/MM/yyyy");
					}),
				},
				colors: ["#E9AF3E", "#4B4EFC"],
			},
		});
	}
}
