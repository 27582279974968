// Components
import DatePicker from "Components/Elements/DatePicker";
import FrequencyPicker, { EFrequency } from "Components/Elements/FrequencyPicker";
import I18n from "Components/Elements/I18n";
import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import Filter, { IFilterOption } from "Components/Elements/Filter";
import AnchorRequests from "./Widgets/AnchorRequests";
import Incidents from "./Widgets/Incidents";
import ValidatedAnchors from "./Widgets/ValidatedAnchors";
import AnchorHistogram from "./Widgets/AnchorHistogram";

// API
import DashboardAPI, { IDashboardResponse, DashboardChartsDatas } from "Api/SmartSig/Dashboard";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	date: {
		from: string;
		to: string;
		overridedFrom: string;
		overridedTo: string;
	};
	frequency: EFrequency;
	dataArrays: DashboardChartsDatas;
	filters: IFilterOption[];
};

export default class Dashboard extends BasePage<IProps, IState> {
	private static readonly milisecondsInADay: number = 1000 * 60 * 60 * 24;
	private filters: IFilterOption[] = [
		{
			label: "VERIFIED",
			value: "VERIFIED_ON_CHAIN",
			isChecked: true,
		},
		{
			label: "VERIFYING",
			value: "VERIFYING_ON_CHAIN",
			isChecked: true,
		},
		{
			label: "ATTEMPTING",
			value: "ATTEMPTING",
			isChecked: true,
		},
		{
			label: "QUEUED",
			value: "QUEUED",
			isChecked: true,
		},
		{
			label: "ABANDONED",
			value: "ABANDONED",
			isChecked: true,
		},
	];

	constructor(props: IProps) {
		super(props);

		this.state = {
			date: {
				from: this.getOneWeekAgoDate(),
				to: this.getTodayDate(),
				overridedFrom: this.getOneWeekAgoDate(),
				overridedTo: this.getTodayDate(),
			},
			frequency: EFrequency.DAY,
			dataArrays: {
				xaxis: [],
				all: { currentTotal: [], previousTotal: [] },
				valid: { currentTotal: [], previousTotal: [] },
				error: { currentTotal: [], previousTotal: [] },
				verified: [],
				verifying: [],
				attempt: [],
				queued: [],
				abandoned: [],
			},
			filters: this.filters,
		};

		this.onFilterChange = this.onFilterChange.bind(this);
		this.onFromValueDatePickerChange = this.onFromValueDatePickerChange.bind(this);
		this.onToValueDatePickerChange = this.onToValueDatePickerChange.bind(this);
		this.onFrequencyPickerChange = this.onFrequencyPickerChange.bind(this);
	}

	public override render() {
		return (
			<DefaultTemplate title={I18n.translate("pages.dashboard.page_title")}>
				<div className={classes["root"]}>
					<h1 className={classes["title"]}>
						<I18n map="pages.dashboard.title" />
					</h1>

					<div className={classes["time-picker-container"]}>
						<div className={classes["date-picker-container"]}>
							<div className={classes["date-picker"]}>
								<p className={classes["text"]}>
									<I18n map="date_picker.from" />
								</p>
								<DatePicker onChange={this.onFromValueDatePickerChange} initDate={this.state.date.from} />
							</div>

							<div className={classes["date-picker"]}>
								<p className={classes["text"]}>
									<I18n map="date_picker.to" />
								</p>
								<DatePicker
									onChange={this.onToValueDatePickerChange}
									minDate={this.state.date.from}
									initDate={this.state.date.to}
								/>
							</div>
						</div>

						<FrequencyPicker onChange={this.onFrequencyPickerChange} className={classes["frenquency-picker"]} />
					</div>

					<div className={classes["line-charts-container"]}>
						<h2 className={classes["subtitle"]}>
							<I18n map="pages.dashboard.subtitle.general" />
						</h2>

						<div className={classes["line-charts"]}>
							<AnchorRequests frequency={this.state.frequency} data={this.state.dataArrays} />
							<ValidatedAnchors frequency={this.state.frequency} data={this.state.dataArrays} />
							<Incidents
								frequency={this.state.frequency}
								data={this.state.dataArrays}
								filter={`from=${this.state.date.from}&to=${this.state.date.to}&state=ABANDONED`}
							/>
						</div>
					</div>

					<div className={classes["bar-chart-container"]}>
						<div className={classes["header"]}>
							<h2 className={classes["subtitle"]}>
								<I18n map="pages.dashboard.subtitle.anchor_histogram" />
							</h2>

							<div className={classes["filter-container"]}>
								<Filter
									label="Filtrer par statut"
									options={this.filters}
									onFilterChange={this.onFilterChange}
									className={classes["filter"]}
								/>
								{/* <Filter
									label="Filtrer par erreur"
									onFilterChange={(filter) => console.log(filter)} // TODO: Implement filter
									options={[]} // TODO: Add options
									className={classes["filter"]}
								/> */}
							</div>
						</div>

						<div className={classes["bar-chart"]}>
							<AnchorHistogram data={this.state.dataArrays} />
						</div>
					</div>
				</div>
			</DefaultTemplate>
		);
	}

	override async componentDidMount() {
		await this.fetchChartsData();
	}

	override async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
		if (
			prevState.date.from === this.state.date.from &&
			prevState.date.to === this.state.date.to &&
			prevState.frequency === this.state.frequency &&
			prevState.filters === this.state.filters
		)
			return;

		if (
			prevState.date.from !== this.state.date.from ||
			prevState.date.to !== this.state.date.to ||
			prevState.frequency !== this.state.frequency ||
			prevState.filters !== this.state.filters
		) {
			await this.fetchChartsData();
		}
	}

	private async fetchChartsData() {
		let overrideFrom: string = this.state.date.from;
		let tempFromDate;
		let overrideTo: string = this.state.date.to;
		let tempToDate;
		switch (this.state.frequency) {
			case "week":
				tempFromDate = new Date(this.state.date.from);
				const annualStart = new Date(tempFromDate.getFullYear() + "-01-01");
				let startTimestamp = annualStart.getTime();
				const yeardayNb = annualStart.getDay();
				let weekDaysShifted = 0;
				if (yeardayNb > 1) {
					weekDaysShifted = 8 - yeardayNb;
				}
				let weekToRemove = 0;
				let weekDayNb = tempFromDate.getDay();
				if (weekDayNb > 1) weekToRemove = 1;

				// This is the timestamp of the first day of the first week of the year
				startTimestamp += weekDaysShifted * Dashboard.milisecondsInADay;

				let fromWeekNumber = Math.ceil((tempFromDate.getTime() - startTimestamp) / (Dashboard.milisecondsInADay * 7));
				tempFromDate = new Date(startTimestamp + Dashboard.milisecondsInADay * 7 * (fromWeekNumber - weekToRemove));
				overrideFrom = tempFromDate.toISOString().slice(0, 10);

				tempToDate = new Date(this.state.date.to);
				let weekToAdd = 0;
				weekDayNb = tempToDate.getDay();
				if (weekDayNb > 1) weekToAdd = 1;

				let toWeekNumber = Math.ceil((tempToDate.getTime() - startTimestamp) / (Dashboard.milisecondsInADay * 7));
				tempToDate = new Date(
					tempFromDate.getTime() +
						Dashboard.milisecondsInADay * 7 * (toWeekNumber - fromWeekNumber + weekToAdd) -
						Dashboard.milisecondsInADay,
				);
				overrideTo = tempToDate.toISOString().slice(0, 10);
				break;

			case "month":
				tempFromDate = new Date(this.state.date.from);
				tempFromDate.setDate(1);
				overrideFrom = tempFromDate.toISOString().slice(0, 10);
				tempToDate = new Date(this.state.date.to);
				tempToDate.setDate(1);
				tempToDate.setMonth(tempToDate.getMonth() + 1);
				tempToDate = new Date(tempToDate.getTime() - Dashboard.milisecondsInADay);
				overrideTo = tempToDate.toISOString().slice(0, 10);
				break;

			case "year":
				tempFromDate = new Date(this.state.date.from);
				tempFromDate.setDate(1);
				tempFromDate.setMonth(0);
				overrideFrom = tempFromDate.toISOString().slice(0, 10);
				tempToDate = new Date(this.state.date.to);
				tempToDate.setDate(1);
				tempToDate.setMonth(0);
				tempToDate.setFullYear(tempToDate.getFullYear() + 1);
				tempToDate = new Date(tempToDate.getTime() - Dashboard.milisecondsInADay);
				overrideTo = tempToDate.toISOString().slice(0, 10);
				break;
		}
		this.setState({
			date: {
				from: this.state.date.from,
				to: this.state.date.to,
				overridedFrom: overrideFrom,
				overridedTo: overrideTo,
			},
		});
		let targetStatuses = "";
		let cpt = 0;
		for (let i = 0; i < this.state.filters.length; i++) {
			if (this.state.filters[i]!.isChecked) {
				if (cpt > 0) targetStatuses += ",";
				targetStatuses += this.state.filters[i]!.value;
				cpt++;
			}
		}
		let rawDataValid, rawDataVerifying, rawDataAttempt, rawDataQueued, rawDataError;
		if (targetStatuses.includes("VERIFIED_ON_CHAIN")) {
			rawDataValid = await DashboardAPI.getInstance().get({
				from: overrideFrom,
				to: overrideTo,
				aggregation_mod: this.state.frequency,
				target_status: "VERIFIED_ON_CHAIN",
			});
		}
		if (targetStatuses.includes("VERIFYING_ON_CHAIN")) {
			rawDataVerifying = await DashboardAPI.getInstance().get({
				from: overrideFrom,
				to: overrideTo,
				aggregation_mod: this.state.frequency,
				target_status: "VERIFYING_ON_CHAIN",
			});
		}
		if (targetStatuses.includes("ATTEMPTING")) {
			rawDataAttempt = await DashboardAPI.getInstance().get({
				from: overrideFrom,
				to: overrideTo,
				aggregation_mod: this.state.frequency,
				target_status: "ATTEMPTING",
			});
		}
		if (targetStatuses.includes("QUEUED")) {
			rawDataQueued = await DashboardAPI.getInstance().get({
				from: overrideFrom,
				to: overrideTo,
				aggregation_mod: this.state.frequency,
				target_status: "QUEUED",
			});
		}
		if (targetStatuses.includes("ABANDONED")) {
			rawDataError = await DashboardAPI.getInstance().get({
				from: overrideFrom,
				to: overrideTo,
				aggregation_mod: this.state.frequency,
				target_status: "ABANDONED",
			});
		}
		const dataArrays = this.completeChartsData(rawDataValid, rawDataVerifying, rawDataAttempt, rawDataQueued, rawDataError);
		if (!dataArrays) return;

		this.setState({
			dataArrays,
		});
	}

	/*
	 *	selectDataByDate:
	 *	Used to retrieve a data that matches the date criteria from our arrays of IDashboardResponse.
	 *  Improvements: for now, the API returns unsorted data due to the date being multi parts of number
	 * 		the function was designed to read a sorted dataSet, the return values of index being 0 can become i + 1 if sorted
	 * 		this allows the function to minimize the number of iterations to do on the dataSet for each call
	 */
	private selectDataByDate(data: IDashboardResponse[], targetDate: Date, startIndex: number) {
		let i;
		for (i = startIndex; i < data.length; i++) {
			switch (this.state.frequency) {
				case "day":
					let dayItem = new Date();
					dayItem.setFullYear(data[i]!.year);
					dayItem.setMonth(data[i]!.month! - 1);
					dayItem.setDate(data[i]!.day!);
					// TODO: If a way of returning sorted data from API is found > return index: i + 1
					// For now we return 0 to avoid skipping unsorted data
					if (dayItem.toISOString().slice(0, 10) === targetDate.toISOString().slice(0, 10)) {
						return { data: data[i], index: 0 };
					}
					break;

				case "week":
					// The idea is to find an interval of values for timestamps that matches the specified week
					if (targetDate.getFullYear() === data[i]!.year) {
						let currentWeekNb = this.getWeekNumberFromDate(targetDate, "from");
						if (data[i]!.week! === currentWeekNb) {
							let dataToReturn = data[i]!;

							// a week value can be split amongst two entries, if : a week is on two months at a time, a week is on two years at a time
							// this should address this specific case by taking the next value in account, and returning it as one
							if (
								i < data.length - 1 &&
								((data[i]!.year === data[i + 1]!.year &&
									data[i]!.month! === data[i + 1]!.month! - 1 &&
									data[i]!.week! === data[i + 1]!.week!) ||
									(data[i]!.year === data[i + 1]!.year + 1 &&
										data[i]!.month! === data[i + 1]!.month! - 11 &&
										data[i]!.week! === data[i + 1]!.week!))
							) {
								let newTotal = parseInt(dataToReturn.total) + parseInt(data[i + 1]!.total);
								dataToReturn.total = newTotal.toString();
							}

							return { data: dataToReturn, index: 0 };
						}
					}
					break;

				case "month":
					let targetMonth: number = targetDate.getMonth() + 1;
					if (targetMonth === data[i]!.month! && targetDate.getFullYear() === data[i]!.year) {
						return { data: data[i], index: 0 };
					}
					break;

				case "year":
					if (targetDate.getFullYear() === data[i]!.year) {
						return { data: data[i], index: i + 1 };
					}
					break;
			}
		}
		return { data: null, index: startIndex };
	}

	private getWeekNumberFromDate(dateSource: Date | string, context: string) {
		const date = new Date(dateSource);

		const annualStart = new Date(date.getFullYear() + "-01-01");
		let startTimestamp = annualStart.getTime();
		const yeardayNb = annualStart.getDay();
		let weekDaysShifted = 0;
		if (yeardayNb > 1) {
			weekDaysShifted = 8 - yeardayNb;
		}
		startTimestamp += weekDaysShifted * Dashboard.milisecondsInADay;

		let weekToAdd = 0;
		if (date.getDay() === 1) weekToAdd = 1;
		if (context === "to") weekToAdd = weekToAdd * -1;

		return Math.ceil((date.getTime() - startTimestamp) / (Dashboard.milisecondsInADay * 7)) + weekToAdd;
	}

	private completeChartsData(
		dataValid?: IDashboardResponse[],
		dataVerif?: IDashboardResponse[],
		dataAttempt?: IDashboardResponse[],
		dataQueued?: IDashboardResponse[],
		dataError?: IDashboardResponse[],
	) {
		let from = new Date(this.state.date.overridedFrom);
		const to = new Date(this.state.date.overridedTo);

		if (from.getTime() > to.getTime()) return;

		let nbIterations = 0;
		let iterationDate = from;

		// This switch calculate the number of dates to provide (nbIterations) inside the from -> to interval
		switch (this.state.frequency) {
			case "day":
				const timestampDiff = to.getTime() + Dashboard.milisecondsInADay - 1 - from.getTime();
				nbIterations = Math.ceil(timestampDiff / Dashboard.milisecondsInADay);
				break;

			case "week":
				let fromWeekNumber = this.getWeekNumberFromDate(from, "from");
				let toWeekNumber = this.getWeekNumberFromDate(to, "to");
				if (toWeekNumber === 0) toWeekNumber = 52;
				if (
					from.getFullYear() === to.getFullYear() ||
					(from.getFullYear() === to.getFullYear() - 1 && to.getMonth() === 0 && to.getDate() === 1)
				) {
					nbIterations = toWeekNumber - fromWeekNumber + 1;
				} else {
					nbIterations = toWeekNumber + 52 - fromWeekNumber;
					nbIterations += (to.getFullYear() - from.getFullYear() - 1) * 52;
				}
				break;

			case "month":
				if (to.getFullYear() === from.getFullYear()) {
					nbIterations = to.getMonth() - from.getMonth() + 1;
				} else {
					nbIterations = to.getMonth() + 1 + 12 - (from.getMonth() + 1) + 1;
					nbIterations += (to.getFullYear() - from.getFullYear() - 1) * 12;
				}
				break;

			case "year":
				nbIterations = to.getFullYear() - from.getFullYear() + 1;
				break;
		}

		let iterationTimestamp = iterationDate.getTime();
		let globalDatas: DashboardChartsDatas = {
			xaxis: [],
			all: { previousTotal: [], currentTotal: [] },
			valid: { previousTotal: [], currentTotal: [] },
			error: { previousTotal: [], currentTotal: [] },
			verified: [],
			verifying: [],
			attempt: [],
			queued: [],
			abandoned: [],
		};
		let indexValidInDataList = 0;
		let indexErrorInDataList = 0;
		let indexVerifyingInDataList = 0;
		let indexAttemptInDataList = 0;
		let indexQueuedInDataList = 0;
		let previousTotalAll = 0;
		let previousTotalValid = 0;
		let previousTotalError = 0;
		let result;
		let retrievedData;
		// This loop will first define the date criteria for the current iteration
		// Then it will call a dedicated function that will search for a corresponding data (selectDataByDate)
		for (let i = 0; i < nbIterations; i++) {
			let currentTotal = 0; 
			let currentTotalAll = 0;
			let dateSliceSize = 10;
			if (i > 0) {
				switch (this.state.frequency) {
					case "day":
						iterationDate.setDate(iterationDate.getDate() + 1);
						break;

					case "week":
						iterationTimestamp += Dashboard.milisecondsInADay * 7;
						iterationDate = new Date(iterationTimestamp);
						break;

					case "month":
						iterationDate.setMonth(iterationDate.getMonth() + 1);
						dateSliceSize = 7;
						break;

					case "year":
						iterationDate.setFullYear(iterationDate.getFullYear() + 1);
						dateSliceSize = 4;
						break;
				}
			} else {
				// If this is the first element, we take the from value (almost unchanged)
				switch (this.state.frequency) {
					case "month":
						iterationDate.setDate(1);
						dateSliceSize = 7;
						break;

					case "year":
						iterationDate.setMonth(0);
						iterationDate.setDate(1);
						dateSliceSize = 4;
						break;
				}
			}

			// Doing special search for every data type:
			//	dataValid (is also verified), dataVerifying,
			//  dataAttempt, dataQueued, dataError (is also abandoned)
			//  dataAll is defined at least, with every type cumulated

			if (this.state.frequency === "week") {
				let tempDate = new Date(iterationDate.getTime() + Dashboard.milisecondsInADay * 6);
				globalDatas.xaxis.push(
					iterationDate.toISOString().slice(0, dateSliceSize) + " > " + tempDate.toISOString().slice(0, dateSliceSize),
				);
			} else {
				globalDatas.xaxis.push(iterationDate.toISOString().slice(0, dateSliceSize));
			}

			// <<<<<<<< dataValid
			if (dataValid) {
				result = this.selectDataByDate(dataValid, iterationDate, indexValidInDataList);
				retrievedData = result.data;
				indexValidInDataList = result.index;

				if (retrievedData) {
					previousTotalValid += parseInt(retrievedData.total);
					previousTotalAll += parseInt(retrievedData.total);
					currentTotal = parseInt(retrievedData.total);
					currentTotalAll = currentTotal;
				} else {
					currentTotal = 0;
				}
				globalDatas.valid.currentTotal.push(currentTotal);
				globalDatas.valid.previousTotal.push(previousTotalValid);
				globalDatas.verified.push(currentTotal);
			}
			if (dataError) {
				// <<<<<<<< dataError
				result = this.selectDataByDate(dataError, iterationDate, indexErrorInDataList);
				retrievedData = result.data;
				indexErrorInDataList = result.index;

				if (retrievedData) {
					previousTotalError += parseInt(retrievedData.total);
					previousTotalAll += parseInt(retrievedData.total);
					currentTotal = parseInt(retrievedData.total);
					currentTotalAll += currentTotal;
				} else {
					currentTotal = 0;
				}
				globalDatas.error.currentTotal.push(currentTotal);
				globalDatas.error.previousTotal.push(previousTotalError);
				globalDatas.abandoned.push(currentTotal);
			}
			if (dataVerif) {
				// <<<<<<<< dataVerif
				result = this.selectDataByDate(dataVerif, iterationDate, indexVerifyingInDataList);
				retrievedData = result.data;
				indexVerifyingInDataList = result.index;

				if (retrievedData) {
					previousTotalAll += parseInt(retrievedData.total);
					currentTotal = parseInt(retrievedData.total);
					currentTotalAll += currentTotal;
				} else {
					currentTotal = 0;
				}
				globalDatas.verifying.push(currentTotal);
			}
			// <<<<<<<< dataAttempt
			if (dataAttempt) {
				result = this.selectDataByDate(dataAttempt, iterationDate, indexAttemptInDataList);
				retrievedData = result.data;
				indexAttemptInDataList = result.index;

				if (retrievedData) {
					previousTotalAll += parseInt(retrievedData.total);
					currentTotal = parseInt(retrievedData.total);
					currentTotalAll += currentTotal;
				} else {
					currentTotal = 0;
				}
				globalDatas.attempt.push(currentTotal);
			}
			if (dataQueued) {
				// <<<<<<<< dataQueued
				result = this.selectDataByDate(dataQueued, iterationDate, indexQueuedInDataList);
				retrievedData = result.data;
				indexQueuedInDataList = result.index;

				if (retrievedData) {
					previousTotalAll += parseInt(retrievedData.total);
					currentTotal = parseInt(retrievedData.total);
					currentTotalAll += currentTotal;
				} else {
					currentTotal = 0;
				}
				globalDatas.queued.push(currentTotal);
			}

			globalDatas.all.currentTotal.push(currentTotalAll);
			globalDatas.all.previousTotal.push(previousTotalAll);
		}

		return globalDatas;
	}

	private onFilterChange(option: IFilterOption) {
		const newFilters = this.state.filters.map((filter) => (filter.value === option.value ? option : filter));
		this.setState({ filters: newFilters });
	}

	private onFrequencyPickerChange(frequency: EFrequency) {
		this.setState({
			frequency: frequency,
		});
	}

	private onFromValueDatePickerChange(date: string) {
		this.setState((prevState) => ({
			date: {
				...prevState.date,
				from: date,
			},
		}));
	}

	private onToValueDatePickerChange(date: string) {
		this.setState((prevState) => ({
			date: {
				...prevState.date,
				to: date,
			},
		}));
	}

	private getTodayDate(): string {
		// Get today's date in YYYY-MM-DD format
		return new Date().toISOString().split("T")[0]!;
	}

	private getOneWeekAgoDate(): string {
		let date = new Date();
		date.setDate(date.getDate() - 7);
		return date.toISOString().split("T")[0]!;
	}
}
