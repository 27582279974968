import { Component } from "react";
import classNames from "classnames";

// Components
import I18n from "../I18n";

// Styles
import classes from "./classes.module.scss";

export enum EFrequency {
	DAY = "day",
	WEEK = "week",
	MONTH = "month",
	YEAR = "year",
}

interface IFrequencyPicker {
	text: string;
	value: EFrequency;
}

type IProps = {
	onChange?: (value: EFrequency) => void;
	className?: string;
};
type IState = {
	activeFrequency: EFrequency;
};

export default class FrequencyPicker extends Component<IProps, IState> {
	private readonly _frenquencies: IFrequencyPicker[] = [
		{
			text: I18n.translate("frequency_picker.daily"),
			value: EFrequency.DAY,
		},
		{
			text: I18n.translate("frequency_picker.weekly"),
			value: EFrequency.WEEK,
		},
		{
			text: I18n.translate("frequency_picker.monthly"),
			value: EFrequency.MONTH,
		},
		{
			text: I18n.translate("frequency_picker.yearly"),
			value: EFrequency.YEAR,
		},
	];

	constructor(props: IProps) {
		super(props);

		this.state = {
			activeFrequency: EFrequency.DAY,
		};

		this.onClick = this.onClick.bind(this);
	}

	override render() {
		return (
			<div className={classNames(classes["root"], this.props.className)}>
				{this._frenquencies.map((frequency) => (
					<span
						className={classNames(classes["text"], {
							[classes["active"]!]: this.state.activeFrequency === frequency.value,
						})}
						onClick={() => this.onClick(frequency.value)}
						key={frequency.value}>
						{frequency.text}
					</span>
				))}
			</div>
		);
	}

	private onClick(frequency: EFrequency) {
		this.props.onChange?.(frequency);
		this.setState({ activeFrequency: frequency });
	}
}
