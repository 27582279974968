import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";

export type ISigninBody = {
	email: string;
	password: string;
};

export interface ISigninResponse {
	jwt: string;
}

export default class User extends BaseApiService {
	private static instance: User;
	protected static readonly baseUrl = Config.getInstance().get().api.secure.concat("login");

	public static getInstance() {
		if (!User.instance) {
			User.instance = new User();
		}

		return User.instance;
	}

	public async post(body: ISigninBody): Promise<ISigninResponse> {
		const url = new URL(User.baseUrl);
		try {
			return this.postRequest(url, body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
