import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";

export interface IncidentsListResponse {
	object: string;
	results: IncidentPage[];
	next_cursor: string;
	has_more: boolean;
	type: string;
	page_or_database: Record<string, unknown>;
	request_id: string;
}

export interface IncidentPage {
	object: string;
	id: string;
	created_time: string;
	last_edited_time: string;
	created_by: IncidentUser;
	last_edited_by: IncidentUser;
	cover: null; // Update this if there are more types
	icon: null; // Update this if there are more types
	parent: IncidentParent;
	archived: boolean;
	properties: Record<string, IncidentProperty>;
	url: string;
	public_url: null | string;
	text?: string;
}

interface IncidentUser {
	object: string;
	id: string;
}

interface IncidentParent {
	type: string;
	database_id: string;
}

type IncidentProperty =
	| IncidentRichTextProperty
	| IncidentEmailProperty
	| IncidentSelectProperty
	| IncidentStatusProperty
	| IncidentCreatedTimeProperty
	| IncidentDateProperty
	| IncidentFilesProperty
	| IncidentTitleProperty
	| IncidentMultiSelectProperty;

export interface IncidentMultiSelectProperty {
	id: string;
	type: "multi_select";
	multi_select: IncidentSelectOption[];
}

export interface IncidentSelectOption {
	id: string;
	name: string;
	color: string;
}

export interface IncidentRichTextProperty {
	id: string;
	type: "rich_text";
	rich_text: IncidentRichText[];
}

export interface IncidentRichText {
	type: "text";
	text: {
		content: string;
		link: null | string;
	};
	annotations: IncidentAnnotations;
	plain_text: string;
	href: null | string;
}

export interface IncidentAnnotations {
	bold: boolean;
	italic: boolean;
	strikethrough: boolean;
	underline: boolean;
	code: boolean;
	color: string;
}

export interface IncidentEmailProperty {
	id: string;
	type: "email";
	email: string;
}

export interface IncidentSelectProperty {
	id: string;
	type: "select";
	select: {
		id: string;
		name: string;
		color: string;
	};
}

export interface IncidentStatusProperty {
	id: string;
	type: "status";
	status: {
		id: string;
		name: string;
		color: string;
	};
}

export interface IncidentCreatedTimeProperty {
	id: string;
	type: "created_time";
	created_time: string;
}

export interface IncidentDateProperty {
	id: string;
	type: "date";
	date: {
		start: string;
		end: null | string;
		time_zone: null | string;
	};
}

interface IncidentFilesProperty {
	id: string;
	type: "files";
	files: any[]; // Update with a more specific type if needed
}

export interface IncidentTitleProperty {
	id: string;
	type: "title";
	title: IncidentRichText[];
}

type IncidentsSearchSearchParams = {
	page_size: string;
	start_cursor?: string;
	search_term?: string;
	statusIn?: string[];
};

export default class Incidents extends BaseApiService {
	private static instance: Incidents;
	protected static readonly baseUrl = Config.getInstance().get().api.secure.concat("incidents");

	public static getInstance() {
		if (!Incidents.instance) {
			Incidents.instance = new Incidents();
		}

		return Incidents.instance;
	}

	public async get(searchParams: IncidentsSearchSearchParams): Promise<IncidentsListResponse> {
		const url = new URL(Incidents.baseUrl);
		searchParams.page_size && url.searchParams.append("page_size", searchParams.page_size);
		searchParams.start_cursor && url.searchParams.append("start_cursor", searchParams.start_cursor);
		if (searchParams.search_term) {
			url.searchParams.append("search_term", searchParams.search_term);
		}

		if (searchParams.statusIn) {
			searchParams.statusIn.forEach((status) => {
				url.searchParams.append("statusIn", status);
			});
		}

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
	public async getMessages(incidentId: string): Promise<IncidentPage[]> {
		const url = new URL(Incidents.baseUrl.concat("/messages"));
		url.searchParams.append("incident_id", incidentId);

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
