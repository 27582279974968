import { Component } from "react";
import classNames from "classnames";

// styles
import classes from "./classes.module.scss";
import I18n from "Components/Elements/I18n";

export enum ETicketStatus {
	OPEN = "OPEN",
	CLOSED = "CLOSED",
	PENDING = "PENDING",
	REOPENED = "REOPENED",
	RESOLVED = "RESOLVED",
	CANCELLED = "CANCELLED",
	IN_PROGRESS = "IN_PROGRESS",
}

type IProps = {
	status: ETicketStatus;
};
type IState = {};

export default class TicketStatus extends Component<IProps, IState> {
	override render() {
		return <span className={classNames(classes["root"], classes[this.props.status.toLowerCase()])}><I18n map={`pages.ticket.${this.props.status.toLowerCase()}`}/></span>;
	}
}
