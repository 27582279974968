import Presign from "Services/Presign";
import JwtStore from "Stores/JwtStore";

export default class StoreWorkflow {
	private static instance: StoreWorkflow;
	private readonly jwtStore = JwtStore.getInstance();

	private constructor() {
		this.initEvents();
	}

	public static getInstance(): StoreWorkflow {
		return (this.instance = this.instance ?? new StoreWorkflow());
	}

	private async initEvents() {
		await this.setJwtPairPresign();
	}

	private async setJwtPairPresign() {
		const accessToken = this.jwtStore.accessToken;
		if (accessToken) return;

		const presignService = new Presign();
		const jwtPair = await presignService.getPresignJwt();
		JwtStore.getInstance().setJwtPair(jwtPair);
	}
}
