import { ChangeEvent, Component, createRef } from "react";
import classNames from "classnames";

// Assets
import { ReactComponent as CalendarIcon } from "assets/images/icons/calendar.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	minDate?: string;
	initDate?: string;
	onChange?: (date: string) => void;
	className?: string;
};
type IState = {
	date: string;
};

export default class DatePicker extends Component<IProps, IState> {
	private readonly _datePickerRef = createRef<HTMLInputElement>();

	constructor(props: IProps) {
		super(props);

		this.state = {
			date: this.getDefaultDate(),
		};

		this.onClick = this.onClick.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	override render() {
		return (
			<div className={classNames(classes["root"], this.props.className)} onClick={this.onClick}>
				<input
					type="date"
					className={classes["date-picker"]}
					onChange={this.onChange}
					min={this.props.minDate}
					ref={this._datePickerRef}
				/>
				<span className={classes["custom-date-picker"]}>
					<CalendarIcon className={classes["calendar-icon"]} /> {this.state.date}
				</span>
			</div>
		);
	}

	private onClick() {
		if (!this._datePickerRef.current) return;
		this._datePickerRef.current.showPicker();
	}

	private onChange(event: ChangeEvent<HTMLInputElement>) {
		event.preventDefault();
		const date = event.target.value;
		const formattedDate = this.getFormattedDate(date);

		this.props.onChange?.(date);
		this.setState({ date: formattedDate });
	}

	private getFormattedDate(value: string) {
		const year = value.slice(0, 4);
		const month = value.slice(5, 7);
		const day = value.slice(8, 10);

		return `${day}/${month}/${year}`;
	}

	private getDefaultDate() {
		let date = new Date();
		if(this.props.initDate) date = new Date(this.props.initDate);

		// Get today's date and format it to dd/mm/yyyy
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		return `${day}/${month}/${year}`;
	}
}
