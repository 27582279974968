import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";

export type AnchorParams = {
	hash_sources: string[];
	callback_url: string;
	callback_config: {
		queued: boolean;
		attempting: boolean;
		verifying: boolean;
		verified: boolean;
		abandoned: boolean;
		nb_try: number;
	};
	title?: string;
};

export type AnchorResponse = {
	job_id: string;
	root_hash: string;
	hash_sources: string[];
	hash_processing: {
		method: string;
		algo: string;
		encoding: string;
	};
	transactions: [
		{
			anchoring_timestamp: null;
			blockchain_name: "Tezos";
			status: "QUEUED";
			anchor_nb_try: 0;
		},
	];
	title?: string;
};

export default class Anchor extends BaseApiService {
	private static instance: Anchor;
	protected static readonly baseUrl = Config.getInstance().get().api.secure.concat("/v2/anchor");

	public static getInstance() {
		if (!Anchor.instance) {
			Anchor.instance = new Anchor();
		}

		return Anchor.instance;
	}

	public async post(params: AnchorParams): Promise<AnchorResponse> {
		const url = new URL(Anchor.baseUrl);
		try {
			return this.postRequest(url, params);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
