import { Component, ReactNode } from "react";

// Assets
import { ReactComponent as Cross } from "assets/images/icons/cross.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	title?: ReactNode;
	isOpen: boolean;
	hasCloseButton?: boolean;
	backgroundClickDisabled?: boolean;
	onClose?: () => void;
	className?: string;
	children?: ReactNode;
};
type IState = {};

export default class Modal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.closeModalOnBackgroundClick = this.closeModalOnBackgroundClick.bind(this);
		this.closePopup = this.closePopup.bind(this);
	}

	override render() {
		if (!this.props.isOpen) return null;

		return (
			<div className={classes["root"]}>
				<div className={classes["background"]} onClick={this.closeModalOnBackgroundClick} />
				<div className={classes["container"]}>
					<div className={classes["header"]}>
						{this.props.title}
						{this.props.hasCloseButton && <Cross className={classes["cross"]} onClick={this.closePopup} />}
					</div>
					{this.props.children}
				</div>
			</div>
		);
	}

	private closePopup() {
		this.props.onClose?.();
	}

	private closeModalOnBackgroundClick() {
		if (this.props.backgroundClickDisabled) return;
		this.closePopup();
	}
}
