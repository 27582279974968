import { ChangeEvent, Component } from "react";
import classNames from "classnames";

// Components
import Button, { EButtonSize, EButtonVariant } from "Components/Elements/Button";

// Assets
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";

// Styles
import classes from "./classes.module.scss";

export enum EFilterPosition {
	LEFT = "left",
	RIGHT = "right",
}

export interface IFilterOption {
	label: string;
	value: string;
	isChecked: boolean;
}

type IProps = {
	label: string;
	options: IFilterOption[];
	onFilterChange: (option: IFilterOption) => void;
	optionsPopupPosition?: EFilterPosition;
	className?: string;
};
type IState = {
	isOpen: boolean;
};

export default class Filter extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			isOpen: false,
		};

		this.toggleOpenState = this.toggleOpenState.bind(this);
		this.onChange = this.onChange.bind(this);
		this.selectAll = this.selectAll.bind(this);
	}

	override render() {
		return (
			<div className={classNames(classes["root"], this.props.className)}>
				<p
					onClick={this.toggleOpenState}
					className={classNames(classes["label"], {
						[classes["label-open"]!]: this.state.isOpen,
					})}>
					{this.props.label} <ArrowDown className={classes["arrow-down-icon"]} />
				</p>

				{this.state.isOpen && (
					<>
						<div className={classes["background"]} onClick={this.toggleOpenState} />
						<div
							className={classNames(
								classes["options-container"],
								this.props.optionsPopupPosition && classes[this.props.optionsPopupPosition],
							)}>
							{this.props.options.map((option) => (
								<div className={classes["option"]} key={option.label}>
									<input
										type="checkbox"
										id={option.label}
										name={option.label}
										value={option.value}
										checked={option.isChecked}
										onChange={this.onChange}
									/>
									<label htmlFor={option.label} className={classes["option-label"]}>
										{option.label}
									</label>
								</div>
							))}

							<Button
								variant={EButtonVariant.SECONDARY}
								sizing={EButtonSize.MEDIUM}
								onClick={this.selectAll}
								className={classes["select-all-button"]}>
								Select all
							</Button>
						</div>
					</>
				)}
			</div>
		);
	}

	private onChange(event: ChangeEvent<HTMLInputElement>) {
		const { name } = event.currentTarget;
		const option = this.props.options.find((option) => option.label === name);
		if (!option) return;
		option.isChecked = !option.isChecked;
		this.props.onFilterChange(option);
	}

	private selectAll() {
		this.props.options.forEach((option) => {
			option.isChecked = true;
			this.props.onFilterChange(option);
		});
	}

	private toggleOpenState() {
		this.setState((prevState) => ({
			isOpen: !prevState.isOpen,
		}));
	}
}
