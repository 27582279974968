import { IAppRule } from "Entities/Rule";
import React from "react";
import { Navigate } from "react-router-dom";
import JwtStore from "Stores/JwtStore";

export enum RulesMode {
	OPTIONAL = "optional",
	NECESSARY = "necessary",
}

type IProps = {
	redirectURI?: string;
	mode: RulesMode;
	rules: IAppRule[];
	children: React.ReactNode;
};

type IState = {
	hasRule: boolean;
};

export default class Rules extends React.Component<IProps, IState> {
	private removeOnUserChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			hasRule: this.getHasRule(),
		};
	}

	public override render() {
		if (this.state.hasRule) {
			return this.props.children;
		}

		if (this.props.redirectURI && !this.state.hasRule) {
			return <Navigate to={this.props.redirectURI} />;
		} else {
			return null;
		}
	}

	public override componentDidMount() {
		this.removeOnUserChange = JwtStore.getInstance().onChange(() => this.onUserRolesChange());
	}

	public override componentWillUnmount() {
		this.removeOnUserChange();
	}

	private onUserRolesChange() {
		this.setState({
			hasRule: this.getHasRule(),
		});
	}

	private getHasRule() {
		if (this.props.mode === RulesMode.NECESSARY) {
			return this.props.rules.every((rule) => JwtStore.getInstance().hasRule(rule.name, rule.action));
		}
		return !!this.props.rules.find((rule) => JwtStore.getInstance().hasRule(rule.name, rule.action));
	}
}
