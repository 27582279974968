import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";

import { Source as SourceEntity } from "Entities/Source";

export default class Source extends BaseApiService {
	private static instance: Source;
	protected static readonly baseUrl = Config.getInstance().get().api.secure.concat("/sources");

	public static getInstance() {
		if (!Source.instance) {
			Source.instance = new Source();
		}

		return Source.instance;
	}

	public async get(params: Partial<SourceEntity>): Promise<Source[]> {
		const url = new URL(Source.baseUrl);
		if (params.data) {
			url.searchParams.append("data", params.data);
		}
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
