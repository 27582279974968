// Components
import { AsyncBatch } from "@smart-chain-fr/asyncbatch";
import I18n from "Components/Elements/I18n";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";

// Styles

import Anchor from "Api/SmartSig/Anchor";
import Source from "Api/SmartSig/Sources";
import Verify from "Api/SmartSig/Verify";
import Button, { EButtonIconPosition, EButtonVariant } from "Components/Elements/Button";
import Loader from "Components/Elements/Loader";
import Module from "Components/Elements/Module";
import Tooltip from "Components/Elements/Tooltip";
import Config from "Configs/Config";
import { ReactComponent as AddFile } from "assets/images/icons/add-file.svg";
import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-right-3.svg";
import { ReactComponent as CheckIcon } from "assets/images/icons/check.svg";
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";
import { ReactComponent as ErrorIcon } from "assets/images/icons/error.svg";
import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import { ReactComponent as RetryIcon } from "assets/images/icons/retry.svg";
import { ReactComponent as WarningIcon } from "assets/images/icons/warning.svg";
import { ReactComponent as CheckHashIcon } from "assets/images/icons/check-hash.svg";
import classNames from "classnames";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import classes from "./classes.module.scss";
interface FileTask {
	fileId: string;
	file: File;
	hash?: string;
	isAlreadyHashed?: boolean;
}

const asyncBatchOptions = {
	autoStart: true, // Automatically start processing
	concurrency: 4, // Maximum concurrent tasks
	rateLimit: {
		msTimeRange: 1000, // Limit requests to one per second
		maxExecution: 5, // Allow a maximum of 5 requests within the time range
	},
};
type IWorkerObj = { worker: Worker; available: boolean };
function Document() {
	const [isDocumentAlreadyAnchored, setDocumentAlreadyAnchored] = useState(false);
	const [fileTasks, setFileTasks] = useState<FileTask[]>([]);
	const workersRef = useRef<IWorkerObj[]>();
	const inputUploadRef = useRef<HTMLInputElement>(null);
	const [step, setStep] = useState(0);
	const [title, setTitle] = useState("");

	/**
	 * Create a callback that will be called by the AsyncBatch
	 */
	const hashFileCallback = useCallback(async function (fileTask: FileTask) {
		const worker = (workersRef.current ?? []).find((worker) => worker.available);
		if (!worker) throw new Error("No worker available");
		return hashFile(fileTask, worker);
	}, []);

	const asyncBatchRef = useRef<AsyncBatch<FileTask, Promise<FileTask>>>(AsyncBatch.create([], hashFileCallback, asyncBatchOptions));

	/**
	 * We need to store the workers in a ref to avoid re-creating them on each render
	 * Generate workers and store them in a ref
	 * Remove workers on unmount
	 */
	useEffect(() => {
		workersRef.current =
			workersRef.current ??
			new Array(asyncBatchOptions.concurrency).fill(null).map(() => {
				return { worker: new Worker("./worker.js"), available: true };
			});

		return () =>
			workersRef.current!.forEach((workerObj) => {
				workerObj.worker.terminate();
			});
	}, []);

	useEffect(() => {
		asyncBatchRef.current.events.onProcessingError(({ error }) => console.error(error));
		asyncBatchRef.current.events.onProcessingSuccess(async (response) => {
			if (response.data.hash) {
				const existingSources = await Source.getInstance().get({ data: response.data.hash });
				if (existingSources.length > 0) {
					response.data.isAlreadyHashed = true;
				}
			}
			setFileTasks((prevFiles) => [...prevFiles]);
		});
	}, []);

	useEffect(() => {
		const refreshIsDocumentAlreadyAnchored = async () => {
			if (!fileTasks.length) return;
			if (fileTasks.some((fileTask) => !fileTask.hash)) return;

			const hashes = fileTasks.map((fileTask) => fileTask.hash!).sort((a, b) => a.localeCompare(b));

			if (!hashes.length) return;
			try {
				const isDocumentAlreadyAnchored = await Verify.getInstance().get({
					hash_sources: hashes,
				});
				if (!isDocumentAlreadyAnchored.error) {
					setDocumentAlreadyAnchored(true);
				}
			} catch (e) {
				setDocumentAlreadyAnchored(false);
			}
		};
		refreshIsDocumentAlreadyAnchored();
	}, [fileTasks]);

	useEffect(() => {
		const input = inputUploadRef.current;
		if (!input) return;
		const onChange = () => {
			if (input?.files) {
				const files = input.files;
				onFilesAdded(Array.from(files));
			}
		};

		input.addEventListener("change", onChange);
		return () => {
			input.removeEventListener("change", onChange);
		};
	}, [fileTasks]);

	const onFilesAdded = (files: File[]) => {
		files.forEach((file) => {
			const fileTask = { fileId: uuid(), file };
			setFileTasks((prevFiles) => [...prevFiles, fileTask]);
			asyncBatchRef.current.add(fileTask);
		});
	};

	const removeFile = (fileId: string) => {
		setFileTasks((prevFiles) => prevFiles.filter((fileTask) => fileTask.fileId !== fileId));
	};

	const cancel = () => {
		setTitle("");
		setFileTasks([]);
		setDocumentAlreadyAnchored(false);
	};

	const anchor = useCallback(async () => {
		try {
			await Anchor.getInstance().post({
				hash_sources: fileTasks.map((fileTask) => fileTask.hash!).sort((a, b) => a.localeCompare(b)),
				callback_url: Config.getInstance().get().callback_url,
				callback_config: Config.getInstance().get().callback_config,
				title: title,
			});
			setStep(2);
		} catch (e) {
			console.error(e);
		}
	}, [fileTasks, title]);

	useEffect(() => {
		if (step === 1) {
			anchor();
		}
	}, [anchor, step]);

	return (
		<DefaultTemplate title={I18n.translate("pages.dashboard.page_title")} onFilesAdded={onFilesAdded}>
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<h1 className={classes["title"]}>
						<I18n map="pages.document.title" />
					</h1>
					<p className={classes["description"]}>
						<I18n map="pages.document.description" />
					</p>
				</div>

				{step === 0 && (
					<div className={classes["content"]}>
						<div className={classes["hash-container"]}>
							<div className={classes["deposit-container"]}>
								<AddFile />
								<div className={classes["drag-to-deposit"]}>
									<I18n map="pages.document.drag_to_deposit" />
								</div>
								<div className={classes["or"]}>
									<I18n map="pages.document.or" />
								</div>
								<input ref={inputUploadRef} type="file" id="fileInput" multiple style={{ display: "none" }}></input>
								<Button variant={EButtonVariant.OUTLINED} onClick={() => inputUploadRef.current?.click()}>
									<I18n map="pages.document.select_file" />
								</Button>
								<div className={classes["title-input-container"]}>
									<div className={classes["title-input-label"]}>
										<I18n map="pages.document.title_label" />
									</div>
									<input
										type="text"
										className={classes["title-input"]}
										placeholder={I18n.translate("pages.document.title_placeholder")}
										value={title}
										onChange={(e) => setTitle(e.target.value)}
									/>
								</div>
							</div>
							{fileTasks.length > 0 && (
								<div className={classes["table-container"]}>
									<table className={classes["table"]}>
										<thead className={classes["header"]}>
											<tr className={classes["background"]} />
											<tr className={classes["row"]}>
												<th className={classes["cell"]}>
													<I18n map="pages.document.files" />
												</th>
												<th className={classes["cell"]}>
													<I18n map="pages.document.file_hash" />
												</th>
												<th className={classes["cell"]}></th>
											</tr>
										</thead>
										<tbody className={classes["body"]}>
											{fileTasks.map((fileTask) => (
												<tr className={classes["row"]} key={fileTask.fileId}>
													<td className={classNames(classes["cell"])} title={fileTask.file.name}>
														{fileTask.file.name}
													</td>
													<td className={classNames(classes["cell"])}>
														{fileTask.hash && (
															<div className={classNames(classes["hash-content"])}>{fileTask.hash}</div>
														)}
													</td>
													{!fileTask.hash && (
														<td className={classNames(classes["cell"])}>
															<div className={classNames(classes["hashing-files"])}>
																<Loader className={classNames(classes["loader"])} />
																<span className={classNames(classes["loading-message"])}>
																	<I18n map="pages.document.hashing_files" />
																</span>
															</div>
														</td>
													)}

													{fileTask.hash && (
														<td className={classNames(classes["cell"])}>
															<div className={classNames(classes["hashing-success"])}>
																{fileTask.isAlreadyHashed && (
																	<Tooltip
																		icon={<InfoIcon />}
																		text={<I18n map="pages.document.document_already_archored" />}
																	/>
																)}
																<div className={classNames(classes["check-icon"])}>
																	<CheckHashIcon />
																</div>

																<div
																	className={classNames(classes["cross-icon"])}
																	onClick={() => removeFile(fileTask.fileId)}>
																	<CrossIcon />
																</div>
															</div>
														</td>
													)}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}
						</div>

						{isDocumentAlreadyAnchored && (
							<div className={classes["alert-container"]}>
								<WarningIcon />
								Le groupe a déjà été ancré
							</div>
						)}

						<div className={classes["action-container"]}>
							<Button variant={EButtonVariant.OUTLINED} onClick={cancel}>
								<I18n map="pages.document.cancel" />
							</Button>
							<Button
								variant={EButtonVariant.PRIMARY}
								onClick={() => {
									setStep(1);
								}}
								disabled={!fileTasks.length || isDocumentAlreadyAnchored || fileTasks.some((fileTask) => !fileTask.hash)}>
								<I18n map="pages.document.anchor" />
							</Button>
						</div>
					</div>
				)}

				{step === 1 && (
					<div className={classes["content"]}>
						<div className={classes["deposit-in-progress"]}>
							<Loader />
							<div className={classes["deposit-title"]}>
								<I18n map="pages.document.deposit-in-progress" />
							</div>
							<div className={classes["deposit-subtitle"]}>
								<I18n map="pages.document.deposit-in-progress-advice" />
							</div>
						</div>
					</div>
				)}
				{step === 2 && (
					<div className={classes["content"]}>
						<div className={classes["deposit-in-progress"]}>
							<CheckIcon />
							<div className={classes["deposit-title"]}>
								<I18n map="pages.document.deposit-done" />
							</div>
							<div className={classes["deposit-subtitle"]}>
								<I18n map="pages.document.deposit-done-advice" />
							</div>
						</div>
						<div className={classes["deposit-in-progress-action"]}>
							<Button
								variant={EButtonVariant.OUTLINED}
								onClick={() => {
									window.location.href = Module.config.pages.History.props.path;
								}}>
								<I18n map="pages.document.view-history" />
							</Button>
							<Button
								variant={EButtonVariant.SECONDARY}
								icon={<ArrowIcon />}
								iconPosition={EButtonIconPosition.END}
								onClick={() => {
									setTitle("");
									setFileTasks([]);
									setStep(0);
								}}>
								<I18n map="pages.document.deposit-other-files" />
							</Button>
						</div>
					</div>
				)}
				{step === 3 && (
					<div className={classes["content"]}>
						<div className={classes["deposit-in-progress"]}>
							<ErrorIcon />
							<div className={classes["deposit-title"]}>
								<I18n map="pages.document.deposit-failure" />
							</div>
							<div className={classes["deposit-subtitle"]}>
								<I18n map="pages.document.deposit-failure-advice" />
							</div>
						</div>
						<div className={classes["deposit-in-progress-action"]}>
							<Button
								variant={EButtonVariant.OUTLINED}
								onClick={() => {
									setStep(1);
								}}
								icon={<RetryIcon />}
								iconPosition={EButtonIconPosition.START}>
								<I18n map="pages.document.retry" />
							</Button>
						</div>
					</div>
				)}
			</div>
		</DefaultTemplate>
	);
}

/**
 * @description Hash a file using a worker
 * @param workerObj The worker object that will be used to hash the file, it will be updated to unavailable during the process
 */
async function hashFile(fileTask: FileTask, workerObj: IWorkerObj) {
	workerObj.available = false;
	const reader = new FileReader();
	reader.onload = async () => {
		const arrayBuffer = reader.result;
		if (!arrayBuffer) throw new Error("No array buffer");
		if (!(arrayBuffer instanceof ArrayBuffer)) throw new Error("Array buffer is not an ArrayBuffer");

		workerObj.worker.postMessage(
			{
				buffer: arrayBuffer,
			},
			[arrayBuffer],
		);
	};
	reader.readAsArrayBuffer(fileTask.file);

	return new Promise<FileTask>((resolve, _reject) => {
		workerObj.worker.onmessage = (e) => {
			workerObj.available = true;
			fileTask.hash = e.data.hash;
			resolve(fileTask);
		};
	});
}

export default memo(Document);
