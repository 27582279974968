import classNames from "classnames";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import fr from "date-fns/locale/fr";
import { Component } from "react";
// Components
import Module from "Components/Elements/Module";

// Entities

// styles
import {
	IncidentCreatedTimeProperty,
	IncidentEmailProperty,
	IncidentPage,
	IncidentRichTextProperty,
	IncidentStatusProperty
} from "Api/SmartSig/Incidents";
import TicketStatus, { ETicketStatus } from "Components/Materials/TicketStatus";
import DownloadMessage from "./DowloadMessage";
import classes from "./classes.module.scss";

type IProps = {
	data: IncidentPage[];
};
type IState = {};

export default class TicketTableBody extends Component<IProps, IState> {
	override render() {
		const dataConfig = Module.config.pages.Ticket.data;
		return (
			<tbody className={classes["root"]}>
				{this.props.data.map((row, index) => (
					<tr key={index} className={classes["row"]}>
						{dataConfig.date.enabled && (
							<td className={classNames(classes["cell"])}>
								{this.formatDate((row.properties["Date de déclaration"] as IncidentCreatedTimeProperty).created_time)}
							</td>
						)}
						{dataConfig.object.enabled && (
							<td className={classes["cell"]}>
								{truncateString(
									(row.properties["Message d'erreur"] as IncidentRichTextProperty).rich_text[0]?.text.content ?? "",
								)}
							</td>
						)}
						{dataConfig.sentBy.enabled && (
							<td className={classes["cell"]}>{(row.properties["Mail"] as IncidentEmailProperty).email}</td>
						)}
						{dataConfig.status.enabled && (
							<td className={classes["cell"]}>
								<TicketStatus status={(row.properties["État"] as IncidentStatusProperty).status.name as ETicketStatus} />
							</td>
						)}

						{dataConfig.messages.enabled && (
							<td className={classNames(classes["cell"])} onClick={(event) => event.stopPropagation()}>
								<DownloadMessage row={row} />
							</td>
						)}
					</tr>
				))}
			</tbody>
		);
	}

	private formatDate(date: string | undefined) {
		if (!date) return "-";
		const zonedDate = utcToZonedTime(new Date(date), "Europe/Paris");
		return format(zonedDate, "dd/MM/yyyy HH:mm", { locale: fr });
	}
}

function truncateString(str: string, maxLength: number = 30): string {
	if (str.length > maxLength) {
		return str.substring(0, maxLength) + "...";
	} else {
		return str;
	}
}
