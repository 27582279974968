import { Component, Fragment } from "react";

// Entities
import { ETxDetailsContent, TxDetails500Error } from "Entities/TxDetails";

// Styles
import classes from "../classes.module.scss";
import TxStatus from "Components/Materials/TxStatus";
import intlFormat from "date-fns/intlFormat";
import I18n from "Components/Elements/I18n";

type Props = {
	popupData: TxDetails500Error;
};
type State = {};

export default class TxDetails500StatusPopupContent extends Component<Props, State> {
	override render() {
		return (
			<div className={classes["root"]}>
				<div className={classes["hash-container"]}>
					<h3 className={classes["subtitle"]}>
						<I18n map={"history_table.group_title"}></I18n>
					</h3>
					<div className={classes["value"]}>{this.props.popupData.title ?? '-'}</div>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.ROOT_HASH}</h3>
					<div className={classes["value"]}>{this.props.popupData.rootHash}</div>
					<div className={classes["divider"]} />
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.HASHES}</h3>
					<div className={classes["value"]}>
						{this.props.popupData.hashes.length > 0 && "[" + this.props.popupData.hashes.join(";\n\r") + "]"}
					</div>
				</div>
				<h2 className={classes["section-title"]}>
					<I18n map="history_popup.anchor_state" />
				</h2>
				<div className={classes["container"]}>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.ANCHORING_BLOCKCHAIN}</h3>
					<div className={classes["value"]}>{this.props.popupData.blockchainName}</div>
				</div>

				<div className={classes["divider"]} />

				<h2 className={classes["section-title"]}>
					<I18n map="history_popup.anchor_step" />
				</h2>

				<div className={classes["container"]}>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.STATUS}</h3>
					<div className={classes["status-list"]}>
						{this.props.popupData.details &&
							this.props.popupData.details.map((detail) => (
								<>
									<TxStatus key={detail.details.id} status={detail.details.new_state} />
									{intlFormat(new Date(detail.created_at), {
										year: "numeric",
										month: "numeric",
										day: "numeric",
										hour: "numeric",
										minute: "numeric",
										second: "numeric",
										timeZone: "Europe/Paris",
									})}
								</>
							))}
					</div>
				</div>
			</div>
		);
	}
}
