import { Component } from "react";

// Entities
import { ETxDetailsContent, TxDetails400Error } from "Entities/TxDetails";

// Styles
import classes from "../classes.module.scss";

type Props = {
	popupData: TxDetails400Error;
};
type State = {};

export default class TxDetails4OOStatusPopupContent extends Component<Props, State> {
	override render() {
		return (
			<div className={classes["root"]}>
				<div className={classes["container"]}>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.HTTP_CODE}</h3>
					<div className={classes["value"]}>{this.props.popupData.httpCode}</div>
				</div>

				<div className={classes["divider"]} />

				<div className={classes["container"]}>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.BODY_QUERY}</h3>
					<div className={classes["value"]}>{this.props.popupData.bodyQuery}</div>
				</div>

				<div className={classes["divider"]} />

				<div className={classes["container"]}>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.MESSAGE}</h3>
					<div className={classes["value"]}>{this.props.popupData.message}</div>
				</div>
			</div>
		);
	}
}
