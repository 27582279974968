import { Component } from "react";

// Components
import StackedBarsChart from "Components/Materials/Charts/StackedBarsChart";
import { DashboardChartsDatas } from "Api/SmartSig/Dashboard";

type IProps = {
	data: DashboardChartsDatas;
};
type IState = {};

export default class AnchorHistogram extends Component<IProps, IState> {
	override render() {
		return <StackedBarsChart data={{xaxis: this.props.data.xaxis, valid: this.props.data.verified, verif: this.props.data.verifying, attempt: this.props.data.attempt, queued: this.props.data.queued, error: this.props.data.abandoned}} />;
	}

	override componentDidMount() {
		// TODO: Fetch data from API and pass it to the chart
	}
}
