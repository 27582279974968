import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// Components
import I18n from "Components/Elements/I18n";
import Module from "Components/Elements/Module";

// Assets
import { ReactComponent as BurgerMenuIcon } from "assets/images/icons/burger-menu.svg";
import { ReactComponent as DashboardIcon } from "assets/images/icons/dashboard.svg";
import { ReactComponent as ClockIcon } from "assets/images/icons/clock.svg";
import { ReactComponent as NotificationIcon } from "assets/images/icons/notification.svg";
import SmartChainLogoFull from "assets/images/logos/smart-chain-full.png";

// Styles
import classes from "./classes.module.scss";
import Notifications from "../Notifications";

type IProps = {};
type IState = {
	isMenuOpen: boolean;
	isNotificationsOpen: boolean;
};

export default class TopMenu extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isMenuOpen: false,
			isNotificationsOpen: false,
		};
		this.toggleMenu = this.toggleMenu.bind(this);
		this.onResize = this.onResize.bind(this);
		this.toggleNotifications = this.toggleNotifications.bind(this);
	}

	public override render() {
		return (
			<div className={classes["root"]}>
				<NavLink to={Module.config.pages.Dashboard.props.path} className={classes["logo-container"]}>
					<img src={SmartChainLogoFull} alt="Smart chain logo" className={classes["logo"]} />
				</NavLink>

				<div className={classes["icons-container"]}>
					<NotificationIcon className={classes["notifications-icon"]} onClick={this.toggleNotifications} />
					<BurgerMenuIcon className={classes["burger-menu-icon"]} onClick={this.toggleMenu} />
				</div>

				{this.state.isMenuOpen && <div className={classes["background"]} onClick={this.toggleMenu} />}

				{this.state.isMenuOpen && (
					<div className={classes["menu-container"]}>
						<NavLink
							to={Module.config.pages.Dashboard.props.path}
							className={classNames(classes["navlink-container"], {
								[classes["active"]!]: this.isActive(Module.config.pages.Dashboard.props.path),
							})}>
							<DashboardIcon />
							<p className={classes["label"]}>
								<I18n map="menu.dashboard" />
							</p>
						</NavLink>

						<NavLink
							to={Module.config.pages.History.props.path}
							className={classNames(classes["navlink-container"], {
								[classes["active"]!]: this.isActive(Module.config.pages.History.props.path),
							})}>
							<ClockIcon />
							<p className={classes["label"]}>
								<I18n map="menu.history" />
							</p>
						</NavLink>
						<NavLink
							to={Module.config.pages.History.props.path}
							className={classNames(classes["navlink-container"], {
								[classes["active"]!]: this.isActive(Module.config.pages.History.props.path),
							})}>
							<ClockIcon />
							<p className={classes["label"]}>
								<I18n map="menu.history" />
							</p>
						</NavLink>
					</div>
				)}

				{this.state.isNotificationsOpen && <Notifications toggleNotifications={this.toggleNotifications} isInTopMenu />}
			</div>
		);
	}

	override componentDidMount(): void {
		window.addEventListener("resize", this.onResize);
		this.onResize();
	}

	override componentWillUnmount(): void {
		window.removeEventListener("resize", this.onResize);
	}

	private onResize() {
		if (window.innerWidth > 424) {
			this.setState({ isMenuOpen: false, isNotificationsOpen: false });
		}
	}

	private toggleMenu() {
		this.setState((prevState) => ({
			isMenuOpen: !prevState.isMenuOpen,
		}));
	}

	private toggleNotifications() {
		this.setState((prevState) => ({
			isNotificationsOpen: !prevState.isNotificationsOpen,
		}));
	}

	private isActive(path: string) {
		return window.location.pathname === path;
	}
}
