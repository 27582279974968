import BaseApiService from "Api/BaseApiService";
import { ETxStatus } from "Components/Materials/TxStatus";
import Config from "Configs/Config";

export interface INotificationSearchParams {
	id?: string;
	from?: string;
	to?: string;
	type?: string;
	nb_page?: number;
}

export interface INotificationResponse {
	id: number;
	type: string;
	name: string;
	details: string;
	message: string;
	created_at: string;
}

export interface NotificationBody {
	id: number;
	txId: string;
	httpCode: number;
	message: string;
	date: string;
}

export interface HistoryBody {
	id: string;
	type: string;
	name: string;
	details: {
		id: string;
		nb_try: number;
		new_state: ETxStatus;
	};
	message: string;
	created_at: string;
}

export default class History extends BaseApiService {
	private static instance: History;
	protected static readonly baseUrl = Config.getInstance().get().api.secure.concat("history");

	public static getInstance() {
		if (!History.instance) {
			History.instance = new History();
		}

		return History.instance;
	}

	public async get(searchParams: INotificationSearchParams): Promise<INotificationResponse[]> {
		const url = new URL(History.baseUrl);
		searchParams.from && url.searchParams.append("from", searchParams.from);
		searchParams.to && url.searchParams.append("to", searchParams.to);
		searchParams.type && url.searchParams.append("type", searchParams.type);
		searchParams.nb_page && url.searchParams.append("nb_page", searchParams.nb_page.toString());

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
	public async getTxDetails(searchParams: INotificationSearchParams): Promise<HistoryBody[]> {
		const url = new URL(History.baseUrl.concat("/transactions"));
		searchParams.id && url.searchParams.append("id", searchParams.id);

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
