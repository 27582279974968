import Modal from "Components/Elements/Modal";
import { Component } from "react";

// Components

import TxDetailsSentStatusPopupContent from "./TxDetailsSentStatusPopupContent";
import TxDetails4OOStatusPopupContent from "./TxDetails400StatusPopupContent";
import TxDetails4O1StatusPopupContent from "./TxDetails401StatusPopupContent";
import TxDetails4O9StatusPopupContent from "./TxDetails409StatusPopupContent";
import TxDetails500StatusPopupContent from "./TxDetails500StatusPopupContent";

// Entities
import {
	TxDetailsSentStatus,
	TxDetailsValidatedStatus,
	TxDetails400Error,
	TxDetails401Error,
	TxDetails409Error,
	TxDetails500Error,
} from "Entities/TxDetails";

// Styles
import classes from "./classes.module.scss";
import TxDetailsPopupContent from "./TxDetailsPopupContent";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	popupData:
		| TxDetailsSentStatus
		| TxDetailsValidatedStatus
		| TxDetails400Error
		| TxDetails401Error
		| TxDetails409Error
		| TxDetails500Error
		| null;
};
type State = {};

export default class TxDetailsPopup extends Component<Props, State> {
	override render() {
		if (!this.props.popupData) return null;
		return (
			<Modal
				title={<h2 className={classes["title"]}>job_id : {this.props.popupData.jobId}</h2>}
				isOpen={this.props.isOpen}
				onClose={this.props.onClose}
				hasCloseButton>
				{this.renderPopupContent()}
			</Modal>
		);
	}
	private renderPopupContent() {
		if (!this.props.popupData) return null;

		// There is surely a better way to do this but I don't have time to figure it out
		switch (this.props.popupData.type) {
			case "SENT":
				return <TxDetailsSentStatusPopupContent popupData={this.props.popupData} />;
			case "VALIDATED":
				return <TxDetailsPopupContent popupData={this.props.popupData} />;
			case "ERROR_400":
				return <TxDetails4OOStatusPopupContent popupData={this.props.popupData} />;
			case "ERROR_401":
				return <TxDetails4O1StatusPopupContent popupData={this.props.popupData} />;
			case "ERROR_409":
				return <TxDetails4O9StatusPopupContent popupData={this.props.popupData} />;
			case "ERROR_500":
				return <TxDetails500StatusPopupContent popupData={this.props.popupData} />;
		}
	}
}
