import { NavigateFunction, useNavigate } from "react-router-dom";

// Components
import Button, { EButtonSize, EButtonVariant } from "Components/Elements/Button";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import Module from "Components/Elements/Module";
import BasePage from "Components/Pages/Base";
import I18n from "Components/Elements/I18n";

// Assets
import { ReactComponent as GradiantBackground } from "assets/images/icons/gradiant-background.svg";

// Stores
import JwtStore from "Stores/JwtStore";

// API
import User from "Api/SmartSig/User";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IPropsClass = IProps & {
	navigate: NavigateFunction;
};
type IState = {
	email: string;
	password: string;
	hasError: boolean;
};

class LoginClass extends BasePage<IPropsClass, IState> {
	constructor(props: IPropsClass) {
		super(props);

		this.state = {
			email: "Sacem@smartsig.com",
			password: "Sacem@123456789",
			hasError: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	public override render() {
		return (
			<DefaultTemplate title={I18n.translate("pages.login.page_title")} isHeadersHidden noPadding>
				<div className={classes["root"]}>
					<div className={classes["left-part"]}>
						<div className={classes["form-container"]}>
							<h1 className={classes["title"]}>
								<I18n map="pages.login.title" />
							</h1>

							<form onSubmit={this.onSubmit}>
								<div className={classes["input-container"]}>
									<label htmlFor="email" className={classes["label"]}>
										<I18n map="pages.login.email" />
									</label>
									<input
										type="email"
										name="email"
										onChange={this.onChange}
										placeholder={I18n.translate("pages.login.email_placeholder")}
										className={classes["input"]}
										required
									/>
								</div>

								<div className={classes["input-container"]}>
									<label htmlFor="password" className={classes["label"]}>
										<I18n map="pages.login.password" />
									</label>
									<input type="password" name="password" onChange={this.onChange} className={classes["input"]} required />
								</div>

								{this.state.hasError && (
									<p className={classes["error"]}>
										<I18n map="pages.login.email_or_password_incorrect" />
									</p>
								)}

								<Button variant={EButtonVariant.PRIMARY} sizing={EButtonSize.MEDIUM} className={classes["login-button"]}>
									<I18n map="pages.login.login_button" />
								</Button>
							</form>
						</div>
					</div>

					<div className={classes["right-part"]}>
						<GradiantBackground className={classes["gradiant-background"]} />
					</div>
				</div>
			</DefaultTemplate>
		);
	}

	private async onSubmit(event: React.FormEvent<HTMLFormElement>) {
		try {
			event.preventDefault();
			const { jwt } = await User.getInstance().post({
				email: this.state.email,
				password: this.state.password,
			});

			JwtStore.getInstance().setJwtPair({accessToken: jwt, refreshToken: jwt});
			this.props.navigate(Module.config.pages.Dashboard.props.path);
		} catch (error) {
			console.error(error);
			this.setState({ hasError: true });
		}
	}


	private onChange(event: React.ChangeEvent<HTMLInputElement>): void {
		const { name, value } = event.currentTarget;
		this.setState((prevState) => ({ ...prevState, [name]: value }));
	}
}

export default function Login() {
	const navigate = useNavigate();

	return <LoginClass navigate={navigate} />;
}
