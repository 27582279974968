import classNames from "classnames";
import { Component } from "react";

// Assets
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";

// API
import HistoryApi, { INotificationResponse, NotificationBody } from "Api/SmartSig/History";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	isInTopMenu?: boolean;
	isMediumView?: boolean;
	isMenuOpen?: boolean | null;
	toggleNotifications?: () => void;
};
type IState = {
	data: NotificationBody[];
};

export default class Notifications extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			data: [],
		};
	}

	override render() {
		return (
			<div
				className={classNames(classes["root"], {
					[classes["root-medium"]!]: this.props.isMediumView,
					[classes["root-open"]!]: this.props.isMenuOpen,
					[classes["root-top-menu"]!]: this.props.isInTopMenu,
				})}>
				<div className={classes["title-container"]}>
					<h2 className={classes["title"]}>Notifications</h2>
					{this.props.isInTopMenu && <CrossIcon className={classes["cross-icon"]} onClick={this.props.toggleNotifications} />}
				</div>

				{this.state.data.map((item) => (
					<div key={item.id} className={classes["notification-container"]}>
						<p className={classes["tx-id"]}>{item.txId}</p>
						<div className={classes["error-container"]}>
							<p className={classes["error"]}>Error {item.httpCode}</p>
							<p className={classes["message"]}>{item.message}</p>
						</div>
						<p className={classes["date"]}>Il y a {item.date}</p>
					</div>
				))}
			</div>
		);
	}

	override async componentDidMount() {
		const data: INotificationResponse[] = await HistoryApi.getInstance().get({
			type: "Job",
		});


		this.setState({
			data: this.formatData(data),
		});
	}

	private formatData(data: INotificationResponse[]): NotificationBody[] {
		let formatedDatas: NotificationBody[] = [];
		let formatedData: NotificationBody;
		let itemCpt = 0;
		for (let i = 0; i < data.length; i++) {
			if (!data[i]!.message) continue;

			const currentDate = new Date();
			const itemDate = new Date(data[i]!.created_at);
			const timestampDiff = currentDate.getTime() - itemDate.getTime();
			let dateDiffInDays;
			let dateDiffInHours;
			let dateDiffInMins;
			let dateDiffAsString = "";
			if (timestampDiff > 1000 * 60 * 60 * 24) {
				dateDiffInHours = Math.floor((timestampDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				dateDiffInDays = Math.floor(timestampDiff / (1000 * 60 * 60 * 24));
				dateDiffAsString = dateDiffInDays + " jours et " + dateDiffInHours + " heures.";
			} else if (timestampDiff > 1000 * 60 * 60) {
				dateDiffInHours = Math.floor(timestampDiff / (1000 * 60 * 60));
				dateDiffInMins = Math.floor((timestampDiff % (1000 * 60 * 60)) / (1000 * 60));
				dateDiffAsString = dateDiffInHours + " heures et " + dateDiffInMins + " minutes.";
			} else {
				dateDiffInMins = Math.floor(timestampDiff / (1000 * 60));
				dateDiffAsString = dateDiffInMins + " minutes.";
			}

			switch (data[i]!.message.slice(0, 7)) {
				case "Http404":
					formatedData = {
						id: data[i]!.id,
						txId: data[i]!.name,
						httpCode: parseInt(data[i]!.message.slice(4, 8)),
						message: "Job Not-Found",
						date: dateDiffAsString,
					};
					itemCpt++;
					break;

				case "Http409":
					formatedData = {
						id: data[i]!.id,
						txId: data[i]!.name,
						httpCode: parseInt(data[i]!.message.slice(4, 8)),
						message: "Hash already written",
						date: dateDiffAsString,
					};
					itemCpt++;
					break;

				case "Http400":
					formatedData = {
						id: data[i]!.id,
						txId: data[i]!.name,
						httpCode: parseInt(data[i]!.message.slice(4, 8)),
						message: "Bad Request",
						date: dateDiffAsString,
					};
					itemCpt++;
					break;

				default:
					continue;
			}

			formatedDatas.push(formatedData);

			if (itemCpt === 4) break;
		}

		return formatedDatas;
	}
}
