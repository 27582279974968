import classNames from "classnames";
import React, { ReactNode } from "react";

// Components
import Module from "Components/Elements/Module";
import Footer from "Components/Materials/Footer";
import SideMenu from "Components/Materials/SideMenu";
import TopMenu from "Components/Materials/TopMenu";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	title: string;
	isHeadersHidden?: boolean;
	noPadding?: boolean;
	children?: ReactNode;
	onFilesAdded?: (files: File[]) => void;
};
type IState = {};

export default class DefaultTemplate extends React.Component<IProps, IState> {
	private onDragRef = React.createRef<HTMLDivElement>();

	constructor(props: IProps) {
		super(props);
		this.onDrop = this.onDrop.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);
		this.onDragEnter = this.onDragEnter.bind(this);
	}

	async onDrop(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault();
		let files: File[] = [];
		const entries = [];

		if (event.dataTransfer.items) {
			for (let i = 0; i < event.dataTransfer.items.length; i++) {
				if (event.dataTransfer.items[i]!.kind === "file") {
					const entry = event.dataTransfer.items[i]!.webkitGetAsEntry();
					if (entry) {
						entries.push(entry);
					}
				}
			}
		}

		// Process files after collecting all entries
		for (const entry of entries) {
			const newFiles = await traverseFileTree(entry);
			files = files.concat(newFiles);
		}
		this.onDragRef.current!.style.pointerEvents = "none";
		this.onDragRef.current!.style.background = "transparent";
		this.props.onFilesAdded && this.props.onFilesAdded(files);
	}

	onDragOver(event: React.DragEvent<HTMLDivElement>) {
		this.onDragRef.current!.style.background = "rgba(0, 0, 0, 0.4)";
		event.preventDefault();
	}

	onDragLeave(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault();
		this.onDragRef.current!.style.background = "transparent";
	}

	onDragEnter(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault();
		this.onDragRef.current!.style.background = "rgba(0, 0, 0, 0.4)";
	}

	public override render(): JSX.Element {
		return (
			<div
				className={classes["root"]}
				onDrop={this.onDrop}
				onDragOver={this.onDragOver}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}>
				<div className={classes["on-drag"]} ref={this.onDragRef} />
				{!this.props.isHeadersHidden && (
					<Module from={Module.config.Menu}>
						<SideMenu />
						<TopMenu />
					</Module>
				)}

				<div className={classes["content-container"]}>
					<div
						className={classNames(classes["content"], {
							[classes["no-padding"]!]: this.props.noPadding,
						})}>
						{this.props.children}
					</div>
				</div>

				<Module from={Module.config.Footer}>
					<Footer />
				</Module>
			</div>
		);
	}

	override componentDidMount() {
		window.document.title = this.props.title;
	}
}

async function traverseFileTree(item: FileSystemEntry, path = "") {
	path = path || "";

	let files: File[] = [];
	if (isFile(item)) {
		// If it's a file, read it
		const newFile = await getFileFromFileEntry(item);
		files.push(newFile);
	} else if (isDirectory(item)) {
		// If it's a directory, get its contents
		var dirReader = item.createReader();
		const entries = await readAllDirectoryEntries(dirReader);

		for (const entry of entries) {
			const newFiles = await traverseFileTree(entry);
			files = files.concat(newFiles);
		}
	}
	return files;
}

function isFile(entry: FileSystemEntry): entry is FileSystemFileEntry {
	return entry.isFile;
}

function isDirectory(entry: FileSystemEntry): entry is FileSystemDirectoryEntry {
	return entry.isDirectory;
}

function getFileFromFileEntry(fileEntry: FileSystemFileEntry) {
	return new Promise<File>((resolve, reject) => fileEntry.file(resolve, reject));
}

function readAllDirectoryEntries(dirReader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> {
	return new Promise((resolve, reject) => {
		dirReader.readEntries((entries) => {
			if (!entries.length) {
				resolve([]);
			} else {
				resolve(entries);
			}
		}, reject);
	});
}
