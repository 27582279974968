import { Component, Fragment } from "react";

// Entities
import { ETxDetailsContent, TxDetails409Error } from "Entities/TxDetails";

// Styles
import classes from "../classes.module.scss";

type Props = {
	popupData: TxDetails409Error;
};
type State = {};

export default class TxDetails4O9StatusPopupContent extends Component<Props, State> {
	override render() {
		return (
			<div className={classes["root"]}>
				<div className={classes["container"]}>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.HTTP_CODE}</h3>
					<div className={classes["value"]}>{this.props.popupData.httpCode}</div>
				</div>

				<div className={classes["divider"]} />

				<div className={classes["container"]}>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.ROOT_HASH}</h3>
					<div className={classes["value"]}>{this.props.popupData.rootHash}</div>
				</div>

				<div className={classes["divider"]} />

				<div className={classes["container"]}>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.HASHES}</h3>
					<div className={classes["value"]}>
						[
						{this.props.popupData.hashes.map((hash, i, arr) => (
							<Fragment key={hash}>{arr.length - 1 === i ? hash + "]" : hash.concat(";") + " "}</Fragment>
						))}
					</div>
				</div>

				<div className={classes["divider"]} />

				<div className={classes["container"]}>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.MESSAGE}</h3>
					<div className={classes["value"]}>{this.props.popupData.message}</div>
				</div>

				<div className={classes["divider"]} />

				<div className={classes["container"]}>
					<h3 className={classes["subtitle"]}>{ETxDetailsContent.TOTAL_ESTIMATED_COST}</h3>
					<div className={classes["value"]}>{this.props.popupData.totalEstimatedCost}</div>
				</div>
			</div>
		);
	}
}
