import { Component } from "react";

// Components
import I18n from "Components/Elements/I18n";

// Assets
import SmartChainLogoFull from "assets/images/logos/smart-chain-full.png";
import SmartChainLogoSquare from "assets/images/logos/smart-chain-square.png";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	isMediumView: boolean;
};

export default class Footer extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isMediumView: false,
		};
		this.onResize = this.onResize.bind(this);
	}

	public override render() {
		return (
			<footer className={classes["root"]}>
				<div className={classes["logo-container"]}>
					{this.state.isMediumView ? (
						<img src={SmartChainLogoSquare} alt="Smart chain logo" className={classes["logo"]} />
					) : (
						<img src={SmartChainLogoFull} alt="Smart chain logo" className={classes["logo"]} />
					)}
				</div>
				<p className={classes["text"]}>
					<I18n map="footer.copyright" />
				</p>
			</footer>
		);
	}

	override componentDidMount() {
		window.addEventListener("resize", this.onResize);
		this.onResize();
	}

	override componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	private onResize() {
		this.setState({
			isMediumView: window.innerWidth < 768,
		});
	}
}
