import { Component } from "react";
import classNames from "classnames";

// Components
import HistoryTableHeader, { ESortDirection } from "./HistoryTableHeader";
import HistoryTableBody from "./HistoryTableBody";

// Entities
import { IHistoryAPIData } from "Entities/History";

// styles
import classes from "./classes.module.scss";

type IProps = {
	bodyData: IHistoryAPIData[];
	className?: string;
	onJobIdClick: (tx: IHistoryAPIData) => void;
	onSort: (sortDirection: ESortDirection, sortKey: keyof IHistoryAPIData) => void;
};
type IState = {};

export default class HistoryTable extends Component<IProps, IState> {
	override render() {
		return (
			<div className={classNames(classes["root"], this.props.className)}>
				<table className={classes["table"]}>
					<HistoryTableHeader />
					<HistoryTableBody data={this.props.bodyData} onJobIdClick={this.props.onJobIdClick} />
				</table>
			</div>
		);
	}
}
