import React, { ReactNode } from "react";

// Components
import I18n from "Components/Elements/I18n";

// Stores
import Toasts from "Stores/Toasts";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	onClick?: () => void;
	value: string;
	children?: ReactNode;
};
type IState = {};

export default class CopyClipboard extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<span className={classes["root"]} onClick={this.onClick}>
				{this.props.children}
			</span>
		);
	}

	private async onClick(e: React.MouseEvent<HTMLSpanElement>) {
		e.preventDefault();
		e.stopPropagation();
		try {
			await navigator.clipboard.writeText(this.props.value);
			Toasts.getInstance().open({
				title: <I18n map="toasts.copy_success" />,
				text: <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{this.props.value}</p>,
				time: 2000,
			});
		} catch (err) {
			console.warn(err);
		}
	}
}
