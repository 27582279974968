import React, { useEffect } from "react";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

// Components
import ToastHandler from "./Materials/ToastsHandler";
import Module from "./Elements/Module";

// Stores
import TopMenuStatus from "Stores/TopMenuStatus";
import ThemeMode from "../Stores/ThemeMode";

// Pages
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import HistoryClass from "./Pages/History";
import HelpClass from "./Pages/Help";
import Rules, { RulesMode } from "./Materials/Rules";
import { AppRuleActions, AppRuleNames } from "Entities/Rule";
import Document from "./Pages/Document";
import Ticket from "./Pages/Ticket";
import Verification from "./Pages/Verification";

type IProps = {};
type IState = {};

export default class Main extends React.Component<IProps, IState> {
	private removeThemeModeOnSwitch = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {};
		this.updateThemeMode();
	}

	public override render(): JSX.Element {
		const pageConfig = Module.config.pages;
		return (
			<Router basename="/">
				<ToastHandler />

				<Routes>
					<Route element={<BindRouter />}>
						<Route
							element={
								<Module from={pageConfig.Login}>
									<Rules
										key={Module.config.pages.Login.props.labelKey}
										redirectURI={Module.config.pages.Dashboard.props.path}
										mode={RulesMode.NECESSARY}
										rules={[{ name: AppRuleNames.users, action: AppRuleActions.signinSelf }]}>
										<Login />
									</Rules>
								</Module>
							}
							path={pageConfig.Login.props.path}
						/>

						<Route
							element={
								<Module from={pageConfig.Dashboard}>
									<Rules
										key={Module.config.pages.Dashboard.props.labelKey}
										redirectURI={Module.config.pages.Login.props.path}
										mode={RulesMode.NECESSARY}
										rules={[{ name: AppRuleNames.dashboard, action: AppRuleActions.read }]}>
										<Dashboard />
									</Rules>
								</Module>
							}
							path={pageConfig.Dashboard.props.path}
						/>
						<Route
							element={
								<Module from={pageConfig.Document}>
									<Rules
										key={Module.config.pages.Document.props.labelKey}
										redirectURI={Module.config.pages.Login.props.path}
										mode={RulesMode.NECESSARY}
										rules={[{ name: AppRuleNames.document, action: AppRuleActions.read }]}>
										<Document />
									</Rules>
								</Module>
							}
							path={pageConfig.Document.props.path}
						/>
						<Route
							element={
								<Module from={pageConfig.Verification}>
									<Rules
										key={Module.config.pages.Verification.props.labelKey}
										redirectURI={Module.config.pages.Login.props.path}
										mode={RulesMode.NECESSARY}
										rules={[{ name: AppRuleNames.verification, action: AppRuleActions.read }]}>
										<Verification />
									</Rules>
								</Module>
							}
							path={pageConfig.Verification.props.path}
						/>
						<Route
							element={
								<Module from={pageConfig.History}>
									<Rules
										key={Module.config.pages.History.props.labelKey}
										redirectURI={Module.config.pages.Login.props.path}
										mode={RulesMode.NECESSARY}
										rules={[{ name: AppRuleNames.history, action: AppRuleActions.read }]}>
										<HistoryClass />
									</Rules>
								</Module>
							}
							path={pageConfig.History.props.path}
						/>
						<Route
							element={
								<Module from={pageConfig.Ticket}>
									<Rules
										key={Module.config.pages.Ticket.props.labelKey}
										redirectURI={Module.config.pages.Login.props.path}
										mode={RulesMode.NECESSARY}
										rules={[{ name: AppRuleNames.ticket, action: AppRuleActions.read }]}>
										<Ticket />
									</Rules>
								</Module>
							}
							path={pageConfig.Ticket.props.path}
						/>

						<Route
							element={
								<Module from={pageConfig.Help}>
									<Rules
										key={Module.config.pages.Help.props.labelKey}
										redirectURI={Module.config.pages.Login.props.path}
										mode={RulesMode.NECESSARY}
										rules={[{ name: AppRuleNames.help, action: AppRuleActions.read }]}>
										<HelpClass />
									</Rules>
								</Module>
							}
							path={pageConfig.Help.props.path}
						/>

						<Route element={<Navigate to={pageConfig.Login.props.path} replace />} path="*" />
					</Route>
				</Routes>
			</Router>
		);
	}

	public override componentDidMount() {
		this.removeThemeModeOnSwitch = ThemeMode.getInstance().onSwitch(() => this.updateThemeMode());
	}

	public override componentWillUnmount() {
		this.removeThemeModeOnSwitch();
	}

	private updateThemeMode() {
		document.body.setAttribute("theme-mode", ThemeMode.getInstance().type);
	}
}

function BindRouter() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		TopMenuStatus.getInstance().close();
		document.body.setAttribute("route", location.pathname);
	}, [location.pathname]);

	return <Outlet />;
}
