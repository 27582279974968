import { HistoryBody } from "Api/SmartSig/History";
import { ETxStatus } from "Components/Materials/TxStatus";

export enum ETxDetailsContent {
	TX_ID = "tx_id",
	JOB_ID = "job_id",
	HTTP_CODE = "Http code",
	BODY_QUERY = "Body query",
	ROOT_HASH = "Root hash",
	HASHES = "Hashes",
	MESSAGE = "Message",
	TOTAL_ESTIMATED_COST = "Total estimated cost",
	BLOCKCHAIN_NAME = "Blockchain name",
	ANCHORING_BLOCKCHAIN = "Blockchain d'ancrage",
	COST_TOKEN = "Cost token",
	COST_USD = "Cost USD",
	STATUS = "Status",
	TX_LINK = "Tx_link",
	ANCHORING_DATE = "Date de l'ancrage on-chain (horodatage)",
	EXPLORER_HASH_ALREADY_ANCHORED = "Explorer of the same hash already anchored",
	ANCHORING_STATE = "Retour de l’API",
	ANCHORING_PROOF = "Preuve d’ancrage",
}

// Generic interface for TxDetails
export interface ITxDetails {
	txId: string;
	jobId: string;
	httpCode: number;
	rootHash: string;
	hashes: string[];
	message: string;
	totalEstimatedCost: string;
	details?: HistoryBody[];
	tx_link?: string;
	title?: string;
}

export interface ITxTransactionDetails {
	blockchainName: string;
	costToken: string;
	costUsd: string;
	status: ETxStatus;
}

// Type for every status
// Code 200 SENT
export type TxDetailsSentStatus = ITxDetails &
	ITxTransactionDetails & {
		type: "SENT";
	};

// Code 200 VALIDATED
export type TxDetailsValidatedStatus = ITxDetails &
	ITxTransactionDetails & {
		type: "VALIDATED";
		anchorDate: string;
	};

// Code 400
export type TxDetails400Error = {
	type: "ERROR_400";
	txId: string;
	jobId: string;
	httpCode: number;
	bodyQuery: string;
	message: string;
};

// Code 401
export type TxDetails401Error = ITxDetails & {
	type: "ERROR_401";
};

// Code 409
export type TxDetails409Error = ITxDetails & {
	type: "ERROR_409";
};

// Code 500
export type TxDetails500Error = ITxDetails &
	ITxTransactionDetails & {
		type: "ERROR_500";
	};
