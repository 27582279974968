import { DashboardHistoData } from "Api/SmartSig/Dashboard";
import { Component } from "react";
import ReactApexChart from "react-apexcharts";

// Styles
import classes from "./classes.module.scss";
import format from "date-fns/format";

type IProps = {
	data: DashboardHistoData;
};
type IState = {
	options: ApexCharts.ApexOptions | undefined;
	series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
};

export default class StackedBarsChart extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			series: [
				{
					name: "VERIFIED_ON_CHAIN",
					data: [],
					color: "#8CCC82",
				},
				{
					name: "VERIFYING_ON_CHAIN",
					data: [],
					color: "#844BFC",
				},
				{
					name: "ATTEMPTING",
					data: [],
					color: "#7375FD",
				},
				{
					name: "QUEUED",
					data: [],
					color: "#9CA3AF",
				},
				{
					name: "ABANDONED",
					data: [],
					color: "#D93838",
				},
			],
			// Options is the configuration of the chart
			// https://apexcharts.com/react-chart-demos/column-charts/stacked/
			options: {
				chart: {
					stacked: true,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						horizontal: false,
					},
				},
				xaxis: {
					type: "category",
					categories: [],
				},
				fill: {
					opacity: 1,
				},
			},
		};
	}

	override render() {
		return (
			<div className={classes["root"]}>
				<ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
			</div>
		);
	}

	override componentDidMount() {
		this.setChartOptions();
	}

	override componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (prevProps.data === this.props.data) return;

		this.setChartOptions();
	}

	private setChartOptions() {
		this.setState({
			series: [
				{
					name: "VERIFIED_ON_CHAIN",
					data: this.props.data.valid,
					color: "#8CCC82",
				},
				{
					name: "VERIFYING_ON_CHAIN",
					data: this.props.data.verif,
					color: "#844BFC",
				},
				{
					name: "ATTEMPTING",
					data: this.props.data.attempt,
					color: "#7375FD",
				},
				{
					name: "QUEUED",
					data: this.props.data.queued,
					color: "#9CA3AF",
				},
				{
					name: "ABANDONED",
					data: this.props.data.error,
					color: "#D93838",
				},
			],
			// Options is the configuration of the chart
			// https://apexcharts.com/react-chart-demos/column-charts/stacked/
			options: {
				chart: {
					stacked: true,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						horizontal: false,
					},
				},
				xaxis: {
					// TODO: These labels should be dynamic based on the data prop
					type: "category",
					categories: this.props.data.xaxis.map((date) => {
						if (date.includes(">")) {
							return format(new Date(date.split(">")[0]!), "dd/MM/yyyy");
						}
						return format(new Date(date), "dd/MM/yyyy");
					}),
				},
				fill: {
					opacity: 1,
				},
			},
		});
	}
}
