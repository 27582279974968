import { JwtPair } from "Entities/AccessToken";
import { AppRole } from "Entities/Role";
import { AppRuleActions, AppRuleNames } from "Entities/Rule";
import jwt from "jsonwebtoken";

type GenerateTokenParams = {
	data: Record<string, unknown>;
	expiresIn: number;
};

export default class Presign {
	public async getPresignJwt(): Promise<JwtPair> {
		const data = {
			appRoles: [
				{
					id: 1,
					level: 1,
					name: AppRole.PRESIGN,
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
					app_project: {},
					appRules: [
						{
							id: 1,
							name: AppRuleNames.users,
							action: AppRuleActions.signinSelf,
							app_applications: [],
						},
					],
				},
			],
		};
		const accessToken = await this.generateToken({ data, expiresIn: 60 * 60 }); // 1 hour
		const refreshToken = await this.generateToken({ data, expiresIn: 60 * 60 * 24 * 30 }); // 30 days

		return { accessToken, refreshToken };
	}

	public async generateToken({ data, expiresIn }: GenerateTokenParams): Promise<string> {
		return new Promise((resolve, reject) =>
			jwt.sign(data, "privateKey", { expiresIn }, async (err, appRefreshToken) => {
				if (err) reject(err);
				if (!appRefreshToken) throw new Error("Token is malformed");
				resolve(appRefreshToken);
			}),
		);
	}
}
