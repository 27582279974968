import React from "react";
import { ReactComponent as LoaderIcon } from "assets/images/icons/loader-2.svg";
import { ReactComponent as SmallLoaderIcon } from "assets/images/icons/loader-3.svg";

import classes from "./classes.module.scss";

interface IProps {
	className?: string;
	size?: "small" | "medium" | "large";
}

export default class Loader extends React.Component<IProps> {
	public override render(): JSX.Element {
		if (this.props.size === "small") {
			return <SmallLoaderIcon className={[classes["loader"], this.props.className].filter(Boolean).join(" ")} />;
		}
		return <LoaderIcon className={[classes["loader"], this.props.className].filter(Boolean).join(" ")} />;
	}
}
