import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

// Components
import Module from "Components/Elements/Module";
import I18n from "Components/Elements/I18n";
import Button, { EButtonIconPosition, EButtonSize, EButtonVariant } from "Components/Elements/Button";

// Assets
import { ReactComponent as Arrow } from "assets/images/icons/arrow-right.svg";
/* import { ReactComponent as NotificationIcon } from "assets/images/icons/notification.svg"; */
import { ReactComponent as DashboardIcon } from "assets/images/icons/dashboard.svg";
import { ReactComponent as ClockIcon } from "assets/images/icons/clock.svg";
import { ReactComponent as LogoutIcon } from "assets/images/icons/logout.svg";
import { ReactComponent as HelpIcon } from "assets/images/icons/help.svg";
import { ReactComponent as DocumentIcon } from "assets/images/icons/documents.svg";
import { ReactComponent as TicketIcon } from "assets/images/icons/tickets.svg";
import { ReactComponent as VerificationIcon } from "assets/images/icons/verification.svg";
import SmartChainLogoFull from "assets/images/logos/smart-chain-full.png";
import SmartChainLogoSquare from "assets/images/logos/smart-chain-square.png";

// Stores
import JwtStore from "Stores/JwtStore";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	isMediumView: boolean;
	isMenuOpen: boolean | null;
	isNotificationsOpen: boolean;
};

export default class SideMenu extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isMediumView: false,
			isMenuOpen: null,
			isNotificationsOpen: false,
		};
		this.onResize = this.onResize.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.onLogoutClick = this.onLogoutClick.bind(this);
		this.onHelpClick = this.onHelpClick.bind(this);
		//this.toggleNotifications = this.toggleNotifications.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<header
				className={classNames(classes["root"], {
					[classes["root-medium"]!]: this.state.isMediumView,
				})}
				data-is-open={this.state.isMenuOpen}>
				<div className={classes["content"]}>
					<NavLink to={Module.config.pages.Dashboard.props.path} className={classes["logo-container"]}>
						{!this.state.isMediumView || (this.state.isMediumView && this.state.isMenuOpen) ? (
							<img src={SmartChainLogoFull} alt="Smart chain logo" className={classes["logo"]} />
						) : (
							<img src={SmartChainLogoSquare} alt="Smart chain logo" className={classes["logo"]} />
						)}
					</NavLink>

					{this.state.isMediumView && (
						<>
							<div className={classes["menu-container"]} onClick={this.toggleMenu}>
								<Arrow className={classes["arrow-icon"]} />
								<p className={classes["label"]}>
									<I18n map="menu.close" />
								</p>
							</div>

							<div className={classes["divider"]} />
						</>
					)}

					<NavLink
						to={Module.config.pages.Dashboard.props.path}
						className={classNames(classes["menu-container"], {
							[classes["active"]!]: this.isActive(Module.config.pages.Dashboard.props.path),
						})}>
						<DashboardIcon />
						<p className={classes["label"]}>
							<I18n map="menu.dashboard" />
						</p>
					</NavLink>

					<NavLink
						to={Module.config.pages.History.props.path}
						className={classNames(classes["menu-container"], {
							[classes["active"]!]: this.isActive(Module.config.pages.History.props.path),
						})}>
						<ClockIcon />
						<p className={classes["label"]}>
							<I18n map="menu.history" />
						</p>
					</NavLink>
					{Module.config.pages.Ticket.enabled && (
						<NavLink
							to={Module.config.pages.Ticket.props.path}
							className={classNames(classes["menu-container"], {
								[classes["active"]!]: this.isActive(Module.config.pages.Ticket.props.path),
							})}>
							<TicketIcon />
							<p className={classes["label"]}>
								<I18n map="menu.tickets" />
							</p>
						</NavLink>
					)}
					<div className={classes["menu-buttons"]}>
						{this.state.isMediumView && !this.state.isMenuOpen ? (
							<div className={classes["documents-icon"]}>
								<DocumentIcon onClick={() => (window.location.href = Module.config.pages.Document.props.path)} />
							</div>
						) : (
							<Button
								variant={EButtonVariant.PRIMARY}
								sizing={EButtonSize.FULLWIDTH}
								onClick={() => (window.location.href = Module.config.pages.Document.props.path)}>
								<I18n map="menu.document" />
							</Button>
						)}
						{this.state.isMediumView && !this.state.isMenuOpen ? (
							<div className={classes["verification-icon"]}>
								<VerificationIcon onClick={() => (window.location.href = Module.config.pages.Verification.props.path)} />
							</div>
						) : (
							<Button
								variant={EButtonVariant.SECONDARY}
								sizing={EButtonSize.FULLWIDTH}
								onClick={() => (window.location.href = Module.config.pages.Verification.props.path)}>
								<I18n map="menu.verification" />
							</Button>
						)}
					</div>
					{/* <NavLink
						to={Module.config.pages.Document.props.path}
						className={classNames(classes["menu-container"], {
							[classes["active"]!]: this.isActive(Module.config.pages.History.props.path),
						})}>
						<ClockIcon />
						<p className={classes["label"]}>
							<I18n map="menu.document" />
						</p>
					</NavLink> */}
					<div className={classes["bottom-container"]}>
						{this.state.isMediumView && !this.state.isMenuOpen ? (
							<div className={classes["help"]}>
								<HelpIcon onClick={this.onHelpClick} />
							</div>
						) : (
							<div className={classes["help"]}>
								<Button
									variant={EButtonVariant.SECONDARY}
									icon={<HelpIcon />}
									className={classes["help-button"]}
									iconPosition={EButtonIconPosition.START}
									onClick={this.onHelpClick}>
									<I18n map="menu.help" />
								</Button>
							</div>
						)}
						{this.state.isMediumView && !this.state.isMenuOpen ? (
							<div className={classes["logout"]}>
								<LogoutIcon onClick={this.onLogoutClick} />
							</div>
						) : (
							<div className={classes["logout"]}>
								<Button
									variant={EButtonVariant.OUTLINED}
									icon={<LogoutIcon />}
									iconPosition={EButtonIconPosition.START}
									onClick={this.onLogoutClick}>
									<I18n map="menu.disconnect" />
								</Button>
							</div>
						)}
					</div>
				</div>
				{this.state.isMediumView && <div className={classes["mask"]} />}
				{/* 
				{this.state.isNotificationsOpen && (
					<>
						<Notifications isMediumView={this.state.isMediumView} isMenuOpen={this.state.isMenuOpen} />
						<div className={classes["notifications-background"]} onClick={this.toggleNotifications} />
					</>
				)} */}
			</header>
		);
	}

	override componentDidMount(): void {
		window.addEventListener("resize", this.onResize);
		this.onResize();
	}

	override componentWillUnmount(): void {
		window.removeEventListener("resize", this.onResize);
	}

	private toggleMenu() {
		this.setState((prevState) => ({
			isMenuOpen: !prevState.isMenuOpen,
		}));
	}

	/* private toggleNotifications() {
		this.setState((prevState) => ({
			isNotificationsOpen: !prevState.isNotificationsOpen,
		}));
	} */

	private onResize() {
		if (window.innerWidth < 425) {
			this.setState({ isMenuOpen: null, isNotificationsOpen: false });
		}

		if (window.innerWidth < 768 && !this.state.isMediumView) {
			this.setState({ isMediumView: true });
		}

		if (window.innerWidth >= 768 && this.state.isMediumView) {
			this.setState({ isMediumView: false, isMenuOpen: false });
		}
	}

	private isActive(path: string) {
		return window.location.pathname === path;
	}

	private onLogoutClick() {
		JwtStore.getInstance().clearJwtPair();
		window.location.href = Module.config.pages.Login.props.path;
	}

	private onHelpClick() {
		window.location.href = Module.config.pages.Help.props.path;
	}
}
