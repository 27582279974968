import { Component } from "react";
import classNames from "classnames";

// Assets
import { ReactComponent as ArrowLeft } from "assets/images/icons/arrow-left-2.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right-2.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	currentPage: number;
	totalPages: number;
	onChange: (page: number) => void;
	className?: string;
	withPrevNextText?: boolean;
};
type IState = {
	maxButtons: number;
};

export default class Pagination extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			maxButtons: 8,
		};

		this.goToNextPage = this.goToNextPage.bind(this);
		this.goToPrevPage = this.goToPrevPage.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}

	override render() {
		return (
			<div className={classNames(classes["root"], this.props.className)}>
				<div className={classes["cell"]} onClick={this.goToPrevPage}>
					<ArrowLeft className={classNames(classes["icon"], classes["margin-right"])} />
					{this.props.withPrevNextText && "Previous"}
				</div>
				{this.renderPageNumbers()}
				<div className={classes["cell"]} onClick={this.goToNextPage}>
					{this.props.withPrevNextText && "Next"} <ArrowRight className={classNames(classes["icon"], classes["margin-left"])} />
				</div>
			</div>
		);
	}

	override componentDidMount() {
		window.addEventListener("resize", this.handleResize);
		this.handleResize();
	}

	override componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	private renderPageNumbers() {
		const totalPagesArray = Array.from(Array(this.props.totalPages).keys());

		if (this.props.totalPages > this.state.maxButtons && this.props.currentPage + 2 >= this.props.totalPages) {
			return this.renderSimplePagination(
				totalPagesArray.slice(this.props.totalPages - this.state.maxButtons - 1, this.props.totalPages),
			);
		}

		if (this.props.totalPages > this.state.maxButtons) {
			return this.renderPageNumbersWithEllipsis(totalPagesArray);
		}

		return this.renderSimplePagination(totalPagesArray);
	}

	private renderPageNumbersWithEllipsis(totalPagesArray: number[]) {
		if (this.props.currentPage < this.state.maxButtons) {
			return (
				<>
					{totalPagesArray.slice(0, this.state.maxButtons).map((pageNumber) => (
						<div
							key={pageNumber}
							onClick={() => this.props.onChange(pageNumber + 1)}
							className={classNames(classes["page-number"], {
								[classes["active"]!]: pageNumber + 1 === this.props.currentPage,
							})}>
							{pageNumber + 1}
						</div>
					))}
					{this.renderEllipsisAndTotalPages()}
				</>
			);
		}

		return (
			<>
				{totalPagesArray.slice(this.props.currentPage - this.state.maxButtons + 1, this.props.currentPage + 1).map((pageNumber) => (
					<div
						key={pageNumber}
						onClick={() => this.props.onChange(pageNumber + 1)}
						className={classNames(classes["page-number"], {
							[classes["active"]!]: pageNumber + 1 === this.props.currentPage,
						})}>
						{pageNumber + 1}
					</div>
				))}
				{this.renderEllipsisAndTotalPages()}
			</>
		);
	}

	private renderEllipsisAndTotalPages() {
		return (
			<>
				<div className={classes["page-number"]}>...</div>
				<div onClick={() => this.props.onChange(this.props.totalPages)} className={classes["page-number"]}>
					{this.props.totalPages}
				</div>
			</>
		);
	}

	private renderSimplePagination(totalPagesArray: number[]) {
		return totalPagesArray.map((pageNumber) => (
			<div
				key={pageNumber}
				onClick={() => this.props.onChange(pageNumber + 1)}
				className={classNames(classes["page-number"], {
					[classes["active"]!]: pageNumber + 1 === this.props.currentPage,
				})}>
				{pageNumber + 1}
			</div>
		));
	}

	private handleResize() {
		if (window.innerWidth < 600) {
			this.setState({ maxButtons: 3 });
		} else if (window.innerWidth < 900) {
			this.setState({ maxButtons: 5 });
		} else {
			this.setState({ maxButtons: 8 });
		}
	}

	private goToPrevPage() {
		if (this.props.currentPage === 1) return;
		this.props.onChange(this.props.currentPage - 1);
	}

	private goToNextPage() {
		if (this.props.currentPage === this.props.totalPages) return;
		this.props.onChange(this.props.currentPage + 1);
	}
}
