import React from "react";
import { Link } from "react-router-dom";

// Components
import Button, { EButtonSize, EButtonVariant } from "Components/Elements/Button";

// Stores
import Toasts, { IToast as ToastProps } from "Stores/Toasts";

// Assets
import { ReactComponent as Cross } from "assets/images/icons/cross.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	toast: ToastProps;
};

type IState = {
	willClose: boolean;
};

export default class ToastElement extends React.Component<IProps, IState> {
	private closeTimeout = 0;
	constructor(props: IProps) {
		super(props);

		this.state = {
			willClose: false,
		};

		this.onClose = this.onClose.bind(this);
	}

	public override render(): JSX.Element {
		const toast = this.props.toast;
		const style = {
			"--data-duration": `${toast.time}ms`,
		} as React.CSSProperties;
		return (
			<div className={classes["root"]} data-will-close={this.state.willClose}>
				{toast.time !== 0 && <div className={classes["loadbar"]} style={style} />}
				<div className={classes["title-container"]}>
					{toast.title}
					{toast.closable && <Cross className={classes["cross"]} onClick={this.onClose} />}
				</div>
				<div className={classes["text-container"]}>{toast.text}</div>
				{toast.buttonText !== undefined && toast.buttonUrl !== undefined && (
					<Link to={toast.buttonUrl} rel="noreferrer">
						<Button sizing={EButtonSize.SMALL} variant={EButtonVariant.PRIMARY} onClick={() => this.close()}>
							{toast.buttonText}
						</Button>
					</Link>
				)}
			</div>
		);
	}

	public override componentDidMount() {
		if (this.props.toast.time === 0) return;

		this.closeTimeout = window.setTimeout(() => {
			this.close();
		}, this.props.toast.time);
	}

	private onClose(e: React.MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
		this.close();
	}

	private close() {
		window.clearTimeout(this.closeTimeout);
		this.setState({
			willClose: true,
		});
		setTimeout(() => {
			Toasts.getInstance().close(this.props.toast);
		}, 200);
	}
}
