import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";

export type IDashboardSearchParams = {
	from?: string;
	to?: string;
	aggregation_mod: string;
	target_status?: string;
};

export interface IDashboardResponse {
	total: string;
	year: number;
	month?: number;
	week?: number;
	day?: number;
}

export interface DashboardChartsDatas {
	xaxis: string[];
	all: DashboardChartsData;
	valid: DashboardChartsData;
	error: DashboardChartsData;

	verified: number[];
	verifying: number[];
	attempt: number[];
	queued: number[];
	abandoned: number[];
}

export interface DashboardChartsData {
	previousTotal: number[];
	currentTotal: number[];
}

export interface DashboardHistoData {
	xaxis: string[];
	valid: number[];
	verif: number[];
	attempt: number[];
	queued: number[];
	error: number[];
}

export default class Dashboard extends BaseApiService {
	private static instance: Dashboard;
	protected static readonly baseUrl = Config.getInstance().get().api.secure.concat("anchorsStats");

	public static getInstance() {
		if (!Dashboard.instance) {
			Dashboard.instance = new Dashboard();
		}

		return Dashboard.instance;
	}

	public async get(searchParams: IDashboardSearchParams): Promise<IDashboardResponse[]> {
		const url = new URL(Dashboard.baseUrl);
		searchParams.from && url.searchParams.append("from", searchParams.from);
		searchParams.to && url.searchParams.append("to", searchParams.to);
		searchParams.aggregation_mod && url.searchParams.append("aggregation_mod", searchParams.aggregation_mod);
		searchParams.target_status && url.searchParams.append("target_status", searchParams.target_status);
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
