import { Component } from "react";
import classNames from "classnames";

// styles
import classes from "./classes.module.scss";

export enum ETxStatus {
	VERIFIED_ON_CHAIN = "verified_on_chain",
	VERIFYING_ON_CHAIN = "verifying_on_chain",
	ATTEMPTING = "attempting",
	QUEUED = "queued",
	ABANDONED = "abandoned",
}

type IProps = {
	status: ETxStatus;
};
type IState = {};

export default class TxStatus extends Component<IProps, IState> {
	override render() {
		return <span className={classNames(classes["root"], classes[this.props.status.toLowerCase()])}>{this.props.status}</span>;
	}
}
