import { Component } from "react";

// Components
import Module from "Components/Elements/Module";
import I18n from "Components/Elements/I18n";

// styles
import classes from "./classes.module.scss";

export enum ESortDirection {
	ASC = "asc",
	DESC = "desc",
}

type IProps = {};
type IState = {};

export default class TicketTableHeader extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {};
	}

	override render() {
		const dataConfig = Module.config.pages.Ticket.data;
		return (
			<thead className={classes["root"]}>
				<tr className={classes["background"]} />
				<tr className={classes["row"]}>
					{dataConfig.date.enabled && (
						<th className={classes["cell"]}>
							<I18n map="ticket_table.date" />
						</th>
					)}
					{dataConfig.object.enabled && (
						<th className={classes["cell"]}>
							<I18n map="ticket_table.subject" />
						</th>
					)}
					{dataConfig.sentBy.enabled && (
						<th className={classes["cell"]}>
							<I18n map="ticket_table.sent_by" />
						</th>
					)}
					{dataConfig.status.enabled && (
						<th className={classes["cell"]}>
							<I18n map="ticket_table.status" />
						</th>
					)}				
				</tr>
			</thead>
		);
	}
}
