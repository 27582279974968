import { Component } from "react";

// Components
import Module from "Components/Elements/Module";
import I18n from "Components/Elements/I18n";

// styles
import classes from "./classes.module.scss";

export enum ESortDirection {
	ASC = "asc",
	DESC = "desc",
}

type IProps = {};
type IState = {};

export default class HistoryTableHeader extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {};
	}

	override render() {
		const dataConfig = Module.config.pages.History.data;
		return (
			<thead className={classes["root"]}>
				<tr className={classes["background"]} />
				<tr className={classes["row"]}>
					{dataConfig.txId.enabled && (
						<th className={classes["cell"]}>
							<I18n map="history_table.job_id" />
						</th>
					)}
					{dataConfig.title.enabled && (
						<th className={classes["cell"]}>
							<I18n map="history_table.group_title" />
						</th>
					)}
					{dataConfig.rootHash.enabled && (
						<th className={classes["cell"]}>
							<I18n map="history_table.root_hash" />
						</th>
					)}
					{dataConfig.sendingDate.enabled && (
						<th className={classes["cell"]}>
							<I18n map="history_table.sending_date" />
						</th>
					)}
					{dataConfig.validationDate.enabled && (
						<th className={classes["cell"]}>
							<I18n map="history_table.validation_date" />
						</th>
					)}
					{dataConfig.hashesSheetNumber.enabled && (
						<th className={classes["cell"]}>
							<I18n map="history_table.hashes_sheets_number" />
						</th>
					)}
					{dataConfig.status.enabled && (
						<th className={classes["cell"]}>
							<I18n map="history_table.status" />
						</th>
					)}
					{dataConfig.XTZCost.enabled && (
						<th className={classes["cell"]}>
							<I18n map="history_table.XTZ_cost" />
						</th>
					)}
					{dataConfig.retryDate.enabled && (
						<th className={classes["cell"]}>
							<I18n map="history_table.retry_date" />
						</th>
					)}
					{dataConfig.tx_validation_time.enabled && (
						<th className={classes["cell"]}>
							<I18n map="history_table.tx_validation_time" />
						</th>
					)}
				</tr>
			</thead>
		);
	}
}
