import { useLocation, Location } from "react-router-dom";

// Components
import I18n from "Components/Elements/I18n";
import Filter, { IFilterOption } from "Components/Elements/Filter";
import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import HistoryTable from "Components/Materials/HistoryTable";
import TxDetailsPopup from "Components/Materials/TxDetailsPopup";
import { ETxStatus } from "Components/Materials/TxStatus";
import Pagination from "Components/Materials/Pagination";
import DatePicker from "Components/Elements/DatePicker";
import fr from "date-fns/locale/fr";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";

// Entities
import {
	TxDetailsSentStatus,
	TxDetailsValidatedStatus,
	TxDetails400Error,
	TxDetails401Error,
	TxDetails409Error,
	TxDetails500Error,
} from "Entities/TxDetails";
import { IHistoryAPIData } from "Entities/History";

// API
import AnchorListAPI from "Api/SmartSig/AnchorList";
import HistoryAPI from "Api/SmartSig/History";

// Styles
import classes from "./classes.module.scss";
import { ESortDirection } from "Components/Materials/HistoryTable/HistoryTableHeader";
import Button, { EButtonIconPosition, EButtonSize, EButtonVariant } from "Components/Elements/Button";
import Loader from "Components/Elements/Loader";

type IProps = {
	location: Location;
};

type IState = {
	date: {
		from: string;
		to: string;
	};
	prevNextPaginationButtons: boolean;
	totalPages: number;
	currentPage: number;
	historyData: IHistoryAPIData[];
	filters: IFilterOption[];
	isPopupOpen: boolean;
	popupData:
		| TxDetailsSentStatus
		| TxDetailsValidatedStatus
		| TxDetails400Error
		| TxDetails401Error
		| TxDetails409Error
		| TxDetails500Error
		| null;
	isLoadingExport: boolean;
	isLoading: boolean;
	searchQuery: string;
};

class Debouncer {
	private timer: number = 0;

	private constructor() {}

	public static new() {
		return new this();
	}

	public debounce(func: () => Promise<void>, timeout: number) {
		window.clearTimeout(this.timer);
		this.timer = window.setTimeout(() => {
			func();
		}, timeout);
	}
}

class HistoryClass extends BasePage<IProps, IState> {
	private filters: IFilterOption[] = [
		{
			label: "VERIFIED",
			value: "VERIFIED_ON_CHAIN",
			isChecked: true,
		},
		{
			label: "VERIFYING",
			value: "VERIFYING_ON_CHAIN",
			isChecked: true,
		},
		{
			label: "ATTEMPTING",
			value: "ATTEMPTING",
			isChecked: true,
		},
		{
			label: "QUEUED",
			value: "QUEUED",
			isChecked: true,
		},
		{
			label: "ABANDONED",
			value: "ABANDONED",
			isChecked: true,
		},
	];

	private reloadAnchorListDebouncer = Debouncer.new();

	public constructor(props: IProps) {
		super(props);

		this.state = {
			date: {
				from: this.getOneWeekAgoDate(),
				to: this.getTodayDate(),
			},
			prevNextPaginationButtons: false,
			totalPages: 7,
			currentPage: 1,
			filters: this.filters,
			popupData: null,
			isPopupOpen: false,
			historyData: [],
			isLoadingExport: false,
			isLoading: true,
			searchQuery: "",
		};

		this.onFilterChange = this.onFilterChange.bind(this);
		this.onJobIdClick = this.onJobIdClick.bind(this);
		this.onPopupClose = this.onPopupClose.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
		this.onResize = this.onResize.bind(this);
		this.onFromValueDatePickerChange = this.onFromValueDatePickerChange.bind(this);
		this.onToValueDatePickerChange = this.onToValueDatePickerChange.bind(this);
		this.onSort = this.onSort.bind(this);
		this.onExportToCsv = this.onExportToCsv.bind(this);
		this.onSearchInputChange = this.onSearchInputChange.bind(this);
	}

	public override render() {
		return (
			<DefaultTemplate title={I18n.translate("pages.history.page_title")}>
				<div className={classes["root"]}>
					<div className={classes["top-container"]}>
						<h1 className={classes["title"]}>
							<I18n map="pages.history.title" />
						</h1>
						<div className={classes["export-button-container"]}>
							<Button
								variant={EButtonVariant.SECONDARY}
								sizing={EButtonSize.SMALL}
								icon={<DownloadIcon />}
								iconPosition={EButtonIconPosition.START}
								onClick={this.onExportToCsv}>
								<I18n map="pages.history.download_csv" />
							</Button>
						</div>
					</div>
					<div className={classes["filter-container"]}>
						{/* search bar*/}
						<div className={classes["search-bar"]}>
							<input type="text" placeholder={I18n.translate("pages.history.search")} onChange={this.onSearchInputChange} />
						</div>

						<Filter
							className={classes["filter"]}
							label={I18n.translate("pages.history.filter_label")}
							options={this.filters}
							onFilterChange={this.onFilterChange}
						/>
					</div>

					<div className={classes["date-picker-container"]}>
						<div className={classes["date-picker"]}>
							<p className={classes["text"]}>
								<I18n map="date_picker.from" />
							</p>
							<DatePicker
								className={classes["date"]}
								onChange={this.onFromValueDatePickerChange}
								initDate={this.state.date.from}
							/>
						</div>

						<div className={classes["date-picker"]}>
							<p className={classes["text"]}>
								<I18n map="date_picker.to" />
							</p>
							<DatePicker
								className={classes["date"]}
								onChange={this.onToValueDatePickerChange}
								minDate={this.state.date.from}
								initDate={this.state.date.to}
							/>
						</div>
					</div>
					<div className={classes["content-container"]}>
						{this.state.isLoading ? (
							<div className={classes["loader"]}>
								<Loader />
							</div>
						) : (
							<>
								<HistoryTable
									onSort={this.onSort}
									onJobIdClick={this.onJobIdClick}
									bodyData={this.state.historyData}
									className={classes["history-table"]}
								/>
							</>
						)}
						<Pagination
							totalPages={Math.ceil(this.state.totalPages / 10)}
							currentPage={this.state.currentPage}
							onChange={this.onPageChange}
							className={classes["pagination"]}
							withPrevNextText={this.state.prevNextPaginationButtons}
						/>
					</div>

					<TxDetailsPopup popupData={this.state.popupData} isOpen={this.state.isPopupOpen} onClose={this.onPopupClose} />
				</div>
			</DefaultTemplate>
		);
	}

	public override async componentDidMount() {
		window.addEventListener("resize", this.onResize);
		this.onResize();

		// TODO: Check URL for filters and fetch data from API with the filters
		const urlParams = new URLSearchParams(this.props.location.search);
		const fromFilter = urlParams.get("from");
		const toFilter = urlParams.get("to");
		const stateFilter = urlParams.get("state");

		const from = fromFilter ? new Date(fromFilter).toISOString().split("T")[0] : this.state.date.from;
		const to = toFilter ? new Date(toFilter).toISOString().split("T")[0] : this.state.date.to;
		if (stateFilter) {
			this.filters.find((filter) => filter.value === stateFilter)!.isChecked = true;
			this.filters.find((filter) => filter.value !== stateFilter)!.isChecked = false;
		}

		if (from && to) {
			this.setDate(from, to);
			// I return here because the setDate function will call reloadAnchorList
			return;
		}

		this.reloadAnchorList();
	}

	public override componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	private async reloadAnchorList() {
		this.reloadAnchorListDebouncer.debounce(async () => {
			this.setState({ isLoading: true });
			const historyData = await AnchorListAPI.getInstance().get({
				from: this.state.date.from,
				to: this.state.date.to,
				nb_page: this.state.currentPage - 1,
				target_status: this.getCheckedFilters(),
				search_term: this.state.searchQuery,
			});

			this.setState({
				historyData: historyData.anchorsList,
				totalPages: historyData.txCount,
				isLoading: false,
			});
		}, 1000);
		this.setState({ isLoading: false });
	}

	private onSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
		this.setState({ searchQuery: event.target.value }, () => {
			this.reloadAnchorList();
		});
	}

	private setDate(from: string, to: string) {
		this.setState(
			{
				date: {
					from,
					to,
				},
			},
			() => {
				this.reloadAnchorList();
			},
		);
	}

	private onPopupClose() {
		this.setState({ isPopupOpen: false, popupData: null });
	}

	private onFilterChange(option: IFilterOption) {
		const newFilters = this.state.filters.map((filter) => (filter.value === option.value ? option : filter));
		this.setState({ filters: newFilters }, () => {
			this.reloadAnchorList();
		});
	}

	private async onJobIdClick(tx: IHistoryAPIData) {
		if (!tx.sources) tx.sources = [{ data: "", created_at: "" }];
		const hashArray = tx.sources!.map((source) => {
			return source.data;
		});
		const txDetails = await HistoryAPI.getInstance().getTxDetails({ id: tx.txId });

		let txData: TxDetailsValidatedStatus | TxDetailsSentStatus | TxDetails500Error;

		if (
			tx.state.toLocaleLowerCase() === ETxStatus.QUEUED ||
			tx.state.toLocaleLowerCase() === ETxStatus.ATTEMPTING ||
			tx.state.toLocaleLowerCase() === ETxStatus.VERIFYING_ON_CHAIN
		) {
			let etxStatus = ETxStatus.QUEUED;
			if (tx.state.toLocaleLowerCase() === ETxStatus.ATTEMPTING) etxStatus = ETxStatus.ATTEMPTING;
			if (tx.state.toLocaleLowerCase() === ETxStatus.VERIFYING_ON_CHAIN) etxStatus = ETxStatus.VERIFYING_ON_CHAIN;

			txData = {
				txId: tx.txId,
				jobId: tx.jobId,
				httpCode: 201,
				rootHash: tx.rootHash,
				hashes: hashArray,
				message: "-",
				totalEstimatedCost: "TBD",
				type: "SENT",
				blockchainName: "Tezos",
				costToken: "TBD",
				costUsd: "TBD",
				status: etxStatus,
				details: txDetails,
				tx_link: tx.tx_link,
				title: tx.title,
			};
		} else if (tx.state.toLocaleLowerCase() === ETxStatus.ABANDONED) {
			txData = {
				txId: tx.txId,
				jobId: tx.jobId,
				httpCode: 500,
				rootHash: tx.rootHash,
				hashes: hashArray,
				message: "[Could not reach Tezos]",
				totalEstimatedCost: "TBD",
				type: "ERROR_500",
				blockchainName: "Tezos",
				costToken: "TBD",
				costUsd: "TBD",
				status: ETxStatus.ABANDONED,
				details: txDetails,
				tx_link: tx.tx_link,
				title: tx.title,
			};
		} else {
			txData = {
				txId: tx.txId,
				jobId: tx.jobId,
				httpCode: 201,
				rootHash: tx.rootHash,
				hashes: hashArray,
				message: "-",
				totalEstimatedCost: "TBD",
				type: "VALIDATED",
				blockchainName: "Tezos",
				costToken: "TBD",
				costUsd: "TBD",
				status: ETxStatus.VERIFIED_ON_CHAIN,
				tx_link: tx.tx_link,
				anchorDate: tx.anchoredAt!,
				details: txDetails,
				title: tx.title,
			};
		}

		this.setState({ isPopupOpen: true, popupData: txData });
	}

	private onSort(sortDirection: ESortDirection, sortKey: keyof IHistoryAPIData) {
		if (sortKey === "cost" || sortKey === "nbSources") return this.sortNumerically(sortDirection, sortKey);

		if (sortKey === "createdAt" || sortKey === "stateChangedAt" || sortKey === "lastTryAt")
			return this.sortDate(sortDirection, sortKey);

		this.sortAlphabetically(sortDirection, sortKey);
	}

	private sortAlphabetically(sortDirection: ESortDirection, sortKey: keyof IHistoryAPIData) {
		const historyData = this.state.historyData;
		for (const element of historyData) {
			delete element!.sources;
		}
		historyData.sort((a, b) => {
			return sortDirection === ESortDirection.ASC
				? a[sortKey]!.toString().localeCompare(b[sortKey]!.toString())
				: b[sortKey]!.toString().localeCompare(a[sortKey]!.toString());
		});

		this.setState({ historyData });
	}

	private sortNumerically(sortDirection: ESortDirection, sortKey: keyof IHistoryAPIData) {
		const historyData = this.state.historyData;
		for (const element of historyData) {
			delete element!.sources;
		}
		historyData.sort((a, b) => {
			return sortDirection === ESortDirection.ASC ? +a[sortKey]! - +b[sortKey]! : +b[sortKey]! - +a[sortKey]!;
		});

		this.setState({ historyData });
	}

	private sortDate(sortDirection: ESortDirection, sortKey: keyof IHistoryAPIData) {
		const historyData = this.state.historyData;
		historyData.sort((a, b) => {
			const dateA = new Date(+a[sortKey]!.slice(6, 10), +a[sortKey]!.slice(3, 5) - 1, +a[sortKey]!.slice(0, 2));
			const dateB = new Date(+b[sortKey]!.slice(6, 10), +b[sortKey]!.slice(3, 5) - 1, +b[sortKey]!.slice(0, 2));

			return sortDirection === ESortDirection.ASC ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
		});

		this.setState({ historyData });
	}

	private onPageChange(page: number) {
		this.setState({ currentPage: page }, () => {
			this.reloadAnchorList();
		});
	}

	private onResize() {
		if (window.innerWidth < 600) {
			this.setState({ prevNextPaginationButtons: false });
		} else {
			this.setState({ prevNextPaginationButtons: true });
		}
	}

	private onFromValueDatePickerChange(date: string) {
		this.setState(
			(prevState) => ({
				date: {
					...prevState.date,
					from: date,
				},
			}),
			() => {
				this.reloadAnchorList();
			},
		);
	}

	private onToValueDatePickerChange(date: string) {
		this.setState(
			(prevState) => ({
				date: {
					...prevState.date,
					to: date,
				},
			}),
			() => {
				this.reloadAnchorList();
			},
		);
	}

	private getValueFromParams(params: string, key: string) {
		return new URLSearchParams(params).get(key) ?? "";
	}

	private getTodayDate() {
		if (this.props.location.search.length > 0) {
			const extractedValue = this.getValueFromParams(this.props.location.search, "to");
			return new Date(extractedValue).toISOString().split("T")[0]!;
		}
		// Get today's date in YYYY-MM-DD format
		return new Date().toISOString().split("T")[0]!;
	}

	private getOneWeekAgoDate() {
		if (this.props.location.search.length > 0) {
			const extractedValue = this.getValueFromParams(this.props.location.search, "from");
			return new Date(extractedValue).toISOString().split("T")[0]!;
		}
		let date = new Date();
		date.setDate(date.getDate() - 7);
		return date.toISOString().split("T")[0]!;
	}
	private async onExportToCsv() {
		if (this.state.isLoadingExport) return;
		try {
			this.setState({ isLoadingExport: true });

			// Fetch data from API
			const response = await AnchorListAPI.getInstance().get({
				from: this.state.date.from,
				to: this.state.date.to,
				target_status: this.getCheckedFilters(),
			});

			const headers = "Id job,Id transaction,Root Hash,Date de l'ancrage,Nobre de Sources,Lien de la transaction\n";
			const blobParts = [headers];

			response.anchorsList.forEach((anchor) => {
				const row =
					[anchor.jobId, anchor.txId, anchor.rootHash, this.formatDate(anchor.anchoredAt), anchor.nbSources, anchor.tx_link].join(
						",",
					) + "\n";
				blobParts.push(row);
			});

			// Create a Blob from the parts
			const blob = new Blob(blobParts, { type: "text/csv;charset=utf-8;" });
			const url = URL.createObjectURL(blob);

			// Create a link and trigger download
			const generatedDate = this.formatDate(new Date().toString());
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `export-anchors${generatedDate}.csv`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			this.setState({ isLoadingExport: false });
		} catch (error) {
			console.error("Error exporting to CSV:", error);
		}
	}

	private formatDate(date: string | undefined) {
		if (!date) return "-";
		const zonedDate = utcToZonedTime(new Date(date), "Europe/Paris");
		return format(zonedDate, "dd/MM/yyyy HH:mm:ss", { locale: fr });
	}

	private getCheckedFilters(): string {
		return this.state.filters
			.filter((filter) => filter.isChecked)
			.map((filter) => filter.value)
			.join(",");
	}
}

export default function History() {
	const location = useLocation();
	return <HistoryClass location={location} />;
}
