// Components
import { AsyncBatch } from "@smart-chain-fr/asyncbatch";
import I18n from "Components/Elements/I18n";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";

// Styles

import Source from "Api/SmartSig/Sources";
import Verify from "Api/SmartSig/Verify";
import Button, { EButtonIconPosition, EButtonVariant } from "Components/Elements/Button";
import Loader from "Components/Elements/Loader";
import { ReactComponent as AddFile } from "assets/images/icons/add-file.svg";
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";
import { ReactComponent as InfoIcon } from "assets/images/icons/info-verify.svg";
import { ReactComponent as CheckHashIcon } from "assets/images/icons/check-hash.svg";
import { ReactComponent as CrossHashIcon } from "assets/images/icons/rounded-cross.svg";
import { ReactComponent as CopyFileIcon } from "assets/images/icons/copy-file.svg";
import { ReactComponent as RetryIcon } from "assets/images/icons/retry.svg";
import classNames from "classnames";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import classes from "./classes.module.scss";
import CopyClipboard from "Components/Elements/CopyClipboard";
interface FileTask {
	fileId: string;
	file: File;
	hash?: string;
	isAlreadyHashed?: boolean;
}

const asyncBatchOptions = {
	autoStart: true, // Automatically start processing
	concurrency: 4, // Maximum concurrent tasks
	rateLimit: {
		msTimeRange: 1000, // Limit requests to one per second
		maxExecution: 5, // Allow a maximum of 5 requests within the time range
	},
};
type IWorkerObj = { worker: Worker; available: boolean };
function Verification() {
	const [isDocumentAlreadyAnchored, setDocumentAlreadyAnchored] = useState(false);
	const [fileTasks, setFileTasks] = useState<FileTask[]>([]);
	const workersRef = useRef<IWorkerObj[]>();
	const inputUploadRef = useRef<HTMLInputElement>(null);

	/**
	 * Create a callback that will be called by the AsyncBatch
	 */
	const hashFileCallback = useCallback(async function (fileTask: FileTask) {
		const worker = (workersRef.current ?? []).find((worker) => worker.available);
		if (!worker) throw new Error("No worker available");
		return hashFile(fileTask, worker);
	}, []);

	const asyncBatchRef = useRef<AsyncBatch<FileTask, Promise<FileTask>>>(AsyncBatch.create([], hashFileCallback, asyncBatchOptions));

	/**
	 * We need to store the workers in a ref to avoid re-creating them on each render
	 * Generate workers and store them in a ref
	 * Remove workers on unmount
	 */
	useEffect(() => {
		workersRef.current =
			workersRef.current ??
			new Array(asyncBatchOptions.concurrency).fill(null).map(() => {
				return { worker: new Worker("./worker.js"), available: true };
			});

		return () =>
			workersRef.current!.forEach((workerObj) => {
				workerObj.worker.terminate();
			});
	}, []);

	useEffect(() => {
		asyncBatchRef.current.events.onProcessingError(({ error }) => console.error(error));
		asyncBatchRef.current.events.onProcessingSuccess(async (response) => {
			if (response.data.hash) {
				const existingSources = await Source.getInstance().get({ data: response.data.hash });
				if (existingSources.length > 0) {
					response.data.isAlreadyHashed = true;
				}
			}
			setFileTasks((prevFiles) => [...prevFiles]);
		});
	}, []);

	useEffect(() => {
		const refreshIsDocumentAlreadyAnchored = async () => {
			if (!fileTasks.length) return;
			if (fileTasks.some((fileTask) => !fileTask.hash)) return;

			const hashes = fileTasks.map((fileTask) => fileTask.hash!).sort((a, b) => a.localeCompare(b));

			if (!hashes.length) return;
			try {
				const isDocumentAlreadyAnchored = await Verify.getInstance().get({
					hash_sources: hashes,
				});
				if (!isDocumentAlreadyAnchored.error) {
					setDocumentAlreadyAnchored(true);
				}
			} catch (e) {
				setDocumentAlreadyAnchored(false);
			}
		};
		refreshIsDocumentAlreadyAnchored();
	}, [fileTasks]);

	useEffect(() => {
		const input = inputUploadRef.current;
		if (!input) return;
		const onChange = () => {
			if (input?.files) {
				const files = input.files;
				onFilesAdded(Array.from(files));
			}
		};

		input.addEventListener("change", onChange);
		return () => {
			input.removeEventListener("change", onChange);
		};
	}, [fileTasks]);

	const onFilesAdded = (files: File[]) => {
		files.forEach((file) => {
			const fileTask = { fileId: uuid(), file };
			setFileTasks((prevFiles) => [...prevFiles, fileTask]);
			asyncBatchRef.current.add(fileTask);
		});
	};

	const removeFile = (fileId: string) => {
		setFileTasks((prevFiles) => prevFiles.filter((fileTask) => fileTask.fileId !== fileId));
	};

	const cancel = () => {
		setFileTasks([]);
		setDocumentAlreadyAnchored(false);
	};

	return (
		<DefaultTemplate title={I18n.translate("pages.dashboard.page_title")} onFilesAdded={onFilesAdded}>
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<h1 className={classes["title"]}>
						<I18n map="pages.verification.title" />
					</h1>
					<p className={classes["description"]}>
						<I18n map="pages.verification.description" />
					</p>
				</div>

				<div className={classes["content"]}>
					<div className={classes["hash-container"]}>
						<div className={classes["deposit-container"]}>
							<AddFile />
							<div className={classes["drag-to-deposit"]}>
								<I18n map="pages.verification.drag_to_deposit" />
							</div>
							<div className={classes["or"]}>
								<I18n map="pages.verification.or" />
							</div>
							<input ref={inputUploadRef} type="file" id="fileInput" multiple style={{ display: "none" }}></input>
							<Button variant={EButtonVariant.OUTLINED} onClick={() => inputUploadRef.current?.click()}>
								<I18n map="pages.verification.select_file" />
							</Button>
						</div>
						{fileTasks.length > 0 && (
							<div className={classes["table-container"]}>
								<table className={classes["table"]}>
									<thead className={classes["header"]}>
										<tr className={classes["background"]} />
										<tr className={classes["row"]}>
											<th className={classes["cell"]}>
												<I18n map="pages.verification.files" />
											</th>
											<th className={classes["cell"]}>
												<I18n map="pages.verification.file_hash" />
											</th>
											<th className={classes["cell"]}>
												<I18n map="pages.verification.status" />
											</th>
											<th className={classes["cell"]}></th>
										</tr>
									</thead>
									<tbody className={classes["body"]}>
										{fileTasks.map((fileTask) => (
											<tr className={classes["row"]} key={fileTask.fileId}>
												<td className={classNames(classes["cell"])}>{fileTask.file.name}</td>
												<td className={classNames(classes["cell"])}>
													{fileTask.hash && (
														<>
															<div className={classNames(classes["hash-content"])}>
																{fileTask.hash}
																<CopyClipboard value={fileTask.hash}>
																	<CopyFileIcon />
																</CopyClipboard>
															</div>
														</>
													)}
												</td>
												<td className={classNames(classes["cell"])}>
													<div className={classNames(classes["file-status"])}>
														{fileTask.isAlreadyHashed && fileTask.hash && (
															<>
																<div className={classNames(classes["status-icon"])}>
																	<CheckHashIcon />
																</div>
																<div className={classNames(classes["status-text-anchored"])}>
																	<I18n map="pages.verification.already_anchored" />
																</div>
															</>
														)}
														{!fileTask.isAlreadyHashed && fileTask.hash && (
															<>
																<div className={classNames(classes["status-icon"])}>
																	<CrossHashIcon />
																</div>
																<div className={classNames(classes["status-text-unanchored"])}>
																	<I18n map="pages.verification.not_anchored" />
																</div>
															</>
														)}
													</div>
												</td>
												<td className={classNames(classes["cell"])}>
													{!fileTask.hash && (
														<div className={classNames(classes["hashing-files"])}>
															<Loader className={classNames(classes["loader"])} />
															<span className={classNames(classes["loading-message"])}>
																<I18n map="pages.verification.verifiying_files" />
															</span>
														</div>
													)}
													{fileTask.hash && (
														<div className={classNames(classes["hashing-success"])}>
															<div
																className={classNames(classes["cross-icon"])}
																onClick={() => removeFile(fileTask.fileId)}>
																<CrossIcon />
															</div>
														</div>
													)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
					</div>

					{isDocumentAlreadyAnchored && (
						<div className={classes["info-container"]}>
							<InfoIcon />
							<I18n map="pages.verification.files_already_anchored" />
						</div>
					)}

					<div className={classes["action-container"]}>
						<Button
							variant={EButtonVariant.OUTLINED}
							onClick={cancel}
							icon={<RetryIcon />}
							iconPosition={EButtonIconPosition.START}>
							<I18n map="pages.verification.cancel" />
						</Button>
						{/* <Button
							variant={EButtonVariant.PRIMARY}
							onClick={() => {}}
							disabled={!fileTasks.length || isDocumentAlreadyAnchored || fileTasks.some((fileTask) => !fileTask.hash)}>
							<I18n map="pages.verification.verify" />
						</Button> */}
					</div>
				</div>
			</div>
		</DefaultTemplate>
	);
}

/**
 * @description Hash a file using a worker
 * @param workerObj The worker object that will be used to hash the file, it will be updated to unavailable during the process
 */
async function hashFile(fileTask: FileTask, workerObj: IWorkerObj) {
	workerObj.available = false;
	const reader = new FileReader();
	reader.onload = async () => {
		const arrayBuffer = reader.result;
		if (!arrayBuffer) throw new Error("No array buffer");
		if (!(arrayBuffer instanceof ArrayBuffer)) throw new Error("Array buffer is not an ArrayBuffer");

		workerObj.worker.postMessage(
			{
				buffer: arrayBuffer,
			},
			[arrayBuffer],
		);
	};
	reader.readAsArrayBuffer(fileTask.file);

	return new Promise<FileTask>((resolve, _reject) => {
		workerObj.worker.onmessage = (e) => {
			workerObj.available = true;
			fileTask.hash = e.data.hash;
			resolve(fileTask);
		};
	});
}

export default memo(Verification);
