import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";
import { useCallback, useEffect, useState } from "react";
import classes from "./classes.module.scss";

import { Document as DocumentPDF, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import Incidents, { IncidentDateProperty, IncidentMultiSelectProperty, IncidentPage, IncidentRichTextProperty, IncidentTitleProperty } from "Api/SmartSig/Incidents";
import I18n from "Components/Elements/I18n";
import classNames from "classnames";
import Html from "react-pdf-html";
import Loader from "Components/Elements/Loader";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { fr } from "date-fns/locale";

const DownloadMessage = ({ row }: { row: IncidentPage }) => {
	const [pdfData, setPdfData] = useState<IncidentPage[]>([]);

	const fetchData = useCallback(async () => {
		try {
			const incidentId = (row.properties["Id de l'incident"] as IncidentRichTextProperty).rich_text[0]?.text.content;
			if (!incidentId) return;
			const messages = await Incidents.getInstance().getMessages(incidentId);
			setPdfData(messages);
		} catch (error) {
			console.error("Error during API call", error);
			// Handle the error appropriately
		}
	}, [row.properties]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<div className={classNames(classes["download-button"])}>
			{Boolean(pdfData.length) && (
				<PDFDownloadLink
					document={
						<MyDocument
							data={pdfData} // Pass additional data if needed
						/>
					}
					fileName={`${row.id}.pdf`}>
					{({ blob, url, loading, error }) => (loading ? "Loading document..." : <DownloadIcon />)}
				</PDFDownloadLink>
			)}
			{!Boolean(pdfData.length) && <Loader size="small" />}
		</div>
	);
};

// Create styles
const styles = StyleSheet.create({
	page: {
		display: "flex",
		flexDirection: "column",
		color: "#000",
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: "normal",
		padding: "24px",
	},
	line: {
		justifySelf: "center",
		width: "550px",
		height: "1px",
		backgroundColor: "#000",
	},
	row: {
		display: "flex",
		flexDirection: "row",
		gap: "8px",
	},
	content: {
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		marginTop: "16px",
	},
});

const stylesheet = {
	div: {
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: "normal",
	},
	// clear margins for all <p> tags
	//
	// add pink background color to elements with class="special"
};

const MyDocument = ({ data }: { data: IncidentPage[] }) => (
	<DocumentPDF>
		<Page size="A4" style={styles.page}>
			{data.map((row, index) => {
				return (
					<View style={styles.content} key={row.id} break={index > 0}>
						<View style={styles.row}>
							<Text style={styles.row}>
								<I18n map="ticket_message.from"></I18n>:
							</Text>
							<Text style={styles.row}>
								{(row.properties["From"] as IncidentMultiSelectProperty).multi_select[0]?.name ?? ""}
							</Text>
						</View>
						<View style={styles.row}>
							<Text style={styles.row}>
								<I18n map="ticket_message.to"></I18n>:
							</Text>
							<Text style={styles.row}>{(row.properties["To"] as IncidentRichTextProperty).rich_text[0]?.text.content ?? ""}</Text>
						</View>
						<View style={styles.row}>
							<Text style={styles.row}>
								<I18n map="ticket_message.subject"></I18n>:
							</Text>
							<Text style={styles.row}>{(row.properties["Subject"] as IncidentTitleProperty).title[0]?.text.content ?? ""}</Text>
						</View>
						<View style={styles.row}>
							<Text style={styles.row}>{formatDate((row.properties["Date"] as IncidentDateProperty).date.start)}</Text>
						</View>
						<View style={styles.content}>
							<Html stylesheet={stylesheet}>{row.text ?? ""}</Html>
						</View>
						<View style={styles.line}></View>
					</View>
				);
			})}
		</Page>
	</DocumentPDF>
);

function formatDate(date: string | undefined) {
	if (!date) return "-";
	const zonedDate = utcToZonedTime(new Date(date), "Europe/Paris");
	return format(zonedDate, "PPPPpppp", { locale: fr });
}

export default DownloadMessage;
