import { Component } from "react";

// Components
import LineChart from "Components/Materials/Charts/LineChart";
import I18n from "Components/Elements/I18n";
import { EFrequency } from "Components/Elements/FrequencyPicker";
import { DashboardChartsDatas } from "Api/SmartSig/Dashboard";

type IProps = {
	filter?: string;
	frequency: EFrequency;
	data: DashboardChartsDatas;
};
type IState = {};

export default class Incidents extends Component<IProps, IState> {
	override render() {
		return (
			<LineChart
				frequency={this.props.frequency}
				title={I18n.translate("pages.dashboard.widgets.incidents")}
				data={{currentTotal: this.props.data.error.currentTotal, previousTotal: this.props.data.error.previousTotal, xaxis: this.props.data.xaxis}}
				qte={this.props.data.error.previousTotal.length > 0 ? this.props.data.error.previousTotal[this.props.data.error.previousTotal.length - 1]! : 0}
				detail={this.props.filter}
			/>
		);
	}

	override componentDidMount() {
		// TODO: Fetch data from API and pass it to the chart
	}
}
