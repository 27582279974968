import React from "react";
import { useLocation, Location } from "react-router-dom";

// Components
import I18n from "Components/Elements/I18n";
import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";

// Styles
import classes from "./classes.module.scss";
import Config from "Configs/Config";

declare global {
	interface window {
		Tally: {
			loadEmbeds: () => void;
		};
	}
}

type IProps = {
	location: Location;
};

type IState = {};

type IClassProps = IProps & {};

class HelpClass extends BasePage<IClassProps, IState> {
	constructor(props: IClassProps) {
		super(props);
		this.state = {};
	}

	override componentDidMount() {
		// Load all Tally embeds when the component mounts
		const script = document.createElement("script");
		script.src = "https://tally.so/widgets/embed.js";
		script.async = true;
		document.head.appendChild(script);
	}

	public override render() {
		const formId = Config.getInstance().get().tally.formId;
		const formSrc = `https://tally.so/embed/${formId}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`;
		return (
			<DefaultTemplate title={I18n.translate("pages.help.page_title")}>
				<div className={classes["root"]}>
					<iframe
						data-tally-src={formSrc}
						loading="lazy"
						width="100%"
						height="2695"
                        frameBorder="0"
                        marginHeight={0}
						marginWidth={0}
						title="Formulaire de déclaration d'incident - Secure (TEST)"></iframe>
				</div>
			</DefaultTemplate>
		);
	}
}

export default function Help() {
	const location = useLocation();
	return <HelpClass location={location} />;
}
