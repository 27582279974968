import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { TransactionStatus } from "Entities/Transaction";

export type VerifyParams = {
	hash_sources: string[];
};

export type VerifyResponse = {
	error?: string;
	job_id: string;
	root_hash: string;
	hash_sources: string[];
	hash_processing: {
		method: string;
		algo: string;
		encoding: string;
	};
	transactions?: [
		{
			anchoring_timestamp: null;
			blockchain_name: string;
			status: TransactionStatus;
			anchor_nb_try: number;
			tx_id?: number;
			tx_link?: string;
			tx_hash?: string;
		},
	];
};

export default class Verify extends BaseApiService {
	private static instance: Verify;
	protected static readonly baseUrl = Config.getInstance().get().api.secure.concat("/v2/verify");

	public static getInstance() {
		if (!Verify.instance) {
			Verify.instance = new Verify();
		}

		return Verify.instance;
	}

	public async get(params: VerifyParams): Promise<VerifyResponse> {
		const url = new URL(Verify.baseUrl);
		if (params.hash_sources) {
			params.hash_sources.forEach((hash_source) => {
				url.searchParams.append("hash_sources", hash_source);
			});
		}

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
