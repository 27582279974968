import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";

export type IHistorySearchParams = {
	id?: string;
	from?: string;
	to?: string;
	target_status?: string;
	nb_page?: number;
	search_term?: string;
};

export interface IHistoryResponse {
	txCount: number;
	anchorsList: [
		{
			txId: string;
			jobId: string;
			tx_link: string;
			rootHash: string;
			createdAt: string;
			stateChangedAt?: string;
			anchoredAt?: string;
			nbSources: string;
			sources: [
				{
					data: string;
					created_at: string;
				},
			];
			state: string;
			cost: string;
			nbTry: number;
			lastTryAt?: string;
			callbacks?: [
				{
					id: number;
					state: string;
					url: string;
					trigger_state: string;
					max_nb_try: number;
					nb_try: number;
					created_at: string;
					last_try: string;
				},
			];
			callbackReceptionDelay?: string;
		},
	];
}

export default class AnchorList extends BaseApiService {
	private static instance: AnchorList;
	protected static readonly baseUrl = Config.getInstance().get().api.secure.concat("anchorsList");

	public static getInstance() {
		if (!AnchorList.instance) {
			AnchorList.instance = new AnchorList();
		}

		return AnchorList.instance;
	}

	public async get(searchParams: IHistorySearchParams): Promise<IHistoryResponse> {
		const url = new URL(AnchorList.baseUrl);
		searchParams.from && url.searchParams.append("from", searchParams.from);
		searchParams.to && url.searchParams.append("to", searchParams.to);
		searchParams.target_status && url.searchParams.append("target_status", searchParams.target_status);
		searchParams.search_term && url.searchParams.append("search_term", searchParams.search_term);
		(searchParams.nb_page || searchParams.nb_page === 0) && url.searchParams.append("nb_page", searchParams.nb_page.toString());

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
