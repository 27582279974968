export interface IAppRule {
	name: AppRuleNames;
	action: AppRuleActions;
}

export enum AppRuleActions {
	signinSelf = "signin-self",
	read = "read",
}

export enum AppRuleNames {
	users = "users",
	dashboard = "dashboard",
	history = "history",
	help = "help",
	ticket = "ticket",
	document = "document",
	verification = "verification"
}
