import { Document as DocumentPDF, Link, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import classNames from "classnames";
import { format, formatDistanceStrict } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import fr from "date-fns/locale/fr";
import { Component } from "react";
// Components
import Module from "Components/Elements/Module";
import TxStatus, { ETxStatus } from "Components/Materials/TxStatus";
import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";

// Entities
import { IHistoryAPIData } from "Entities/History";

// styles
import I18n from "Components/Elements/I18n";
import { DecodedAccessToken } from "Entities/AccessToken";
import JwtStore from "Stores/JwtStore";
import jwtDecode from "jwt-decode";
import classes from "./classes.module.scss";

type IProps = {
	data: IHistoryAPIData[];
	onJobIdClick: (tx: IHistoryAPIData) => void;
};
type IState = {};

export default class HistoryTableBody extends Component<IProps, IState> {
	override render() {
		const projectName = jwtDecode<DecodedAccessToken>(JwtStore.getInstance().accessToken!).project;
		const dataConfig = Module.config.pages.History.data;
		return (
			<tbody className={classes["root"]}>
				{this.props.data.map((row, index) => (
					<tr key={index} className={classes["row"]} onClick={() => this.props.onJobIdClick(row)}>
						{dataConfig.txId.enabled && (
							<td className={classNames(classes["cell"], classes["tx-id"])}>{row.jobId.substring(0, 12)}</td>
						)}
						{dataConfig.title.enabled && <td className={classes["cell"]}>{row.title ? row.title : "-"}</td>}
						{dataConfig.rootHash.enabled && <td className={classes["cell"]}>{this.formatHash(row.rootHash)}</td>}
						{dataConfig.sendingDate.enabled && <td className={classes["cell"]}>{this.formatDate(row.createdAt)}</td>}
						{dataConfig.validationDate.enabled && (
							<td className={classes["cell"]}>
								{row.state.toLowerCase() !== ETxStatus.ABANDONED ? this.formatDate(row.anchoredAt) : "-"}
							</td>
						)}
						{dataConfig.hashesSheetNumber.enabled && <td className={classes["cell"]}>{row.nbSources}</td>}
						{dataConfig.status.enabled && (
							<td className={classes["cell"]}>
								<TxStatus status={row.state.toLowerCase() as ETxStatus} />
							</td>
						)}
						{dataConfig.XTZCost.enabled && <td className={classes["cell"]}>{row.cost}</td>}
						{dataConfig.retryDate.enabled && <td className={classes["cell"]}>{this.formatDate(row.lastTryAt)}</td>}
						{dataConfig.tx_validation_time.enabled && (
							<td className={classes["cell"]}>
								{row.anchoredAt
									? formatDistanceStrict(new Date(row.anchoredAt), new Date(row.createdAt), { locale: fr })
									: "-"}
							</td>
						)}
						{dataConfig.pdf.enabled && row.anchoredAt && (
							<td className={classNames(classes["cell"])} onClick={(event) => event.stopPropagation()}>
								<div className={classNames(classes["download-button"])}>
									<PDFDownloadLink
										document={
											<MyDocument
												explorerLink={row.tx_link}
												anchoredAt={row.anchoredAt}
												hash={row.rootHash}
												projectName={projectName}
											/>
										}
										fileName={`${row.rootHash}.pdf`}>
										{({ blob, url, loading, error }) => (loading ? "" : <DownloadIcon />)}
									</PDFDownloadLink>
								</div>
							</td>
						)}
					</tr>
				))}
			</tbody>
		);
	}

	private formatDate(date: string | undefined) {
		if (!date) return "-";
		const zonedDate = utcToZonedTime(new Date(date), "Europe/Paris");
		return format(zonedDate, "dd/MM/yyyy HH:mm", { locale: fr });
	}

	private formatHash(hash: string) {
		return hash.slice(0, 5) + "[...]" + hash.slice(hash.length - 6, hash.length);
	}
}

// Create styles
const styles = StyleSheet.create({
	page: {
		display: "flex",
		flexDirection: "column",
		color: "#000",

		// /* Paragraph/XSmall/Regular */
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: "normal",
		padding: " 29px 79px",
	},
	sectionTitle: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginBottom: "50px",
		gap: "8px",
	},

	subtitle: {
		color: "#000",
		/* Heading/H4/Medium */
		fontSize: "28px",
		fontStyle: "normal",
		fontWeight: "semibold",
	},
	line: {
		width: "56px",
		height: "2px",
		backgroundColor: "#000",
	},
	title: {
		/* Heading/H3/Medium */
		fontSize: "32px",
		fontStyle: "normal",
		fontWeight: "semibold",
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
	content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
	separator: {
		height: "1px",
		backgroundColor: "#E7E7E7",
		margin: "21px 0",
		width: "100%",
	},
	row: {
		display: "flex",
		flexDirection: "row",
		marginBottom: "8px",
	},

	rowHeader: {
		minWidth: "128px",
	},

	header: {
		color: "#4B4EFC",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "bold",
		marginBottom: "16px",
	},

	link: {
		color: "#000",
	},
});

type IPropsPDF = {
	hash: string;
	anchoredAt: string;
	projectName: string;
	explorerLink: string;
};

// Create Document Component
const MyDocument = (props: IPropsPDF) => (
	<DocumentPDF style={{ width: "500px" }}>
		<Page size="A4" style={styles.page} orientation="landscape">
			<View style={styles.sectionTitle}>
				<Text style={styles.subtitle}>Secure</Text>
				<View style={styles.line}></View>
				<Text style={styles.title}>
					<I18n map="pages.certificate.title"></I18n>
				</Text>
				<Text>{new Date(props.anchoredAt).toISOString()}</Text>
			</View>
			<View style={styles.content}>
				<View style={styles.row}>
					<Text style={styles.rowHeader}>Hash :</Text>
					<Text>{props.hash}</Text>
				</View>
				<View style={styles.separator}></View>
				<Text style={styles.header}>
					<I18n map="pages.certificate.depositors"></I18n>
				</Text>
				<View style={styles.row}>
					<Text style={styles.rowHeader}>
						<I18n map="pages.certificate.depositary"></I18n> :
					</Text>
					<Text>{props.projectName}</Text>
				</View>
				<View style={styles.row}>
					<Text style={styles.rowHeader}>
						<I18n map="pages.certificate.technical_partner"></I18n> :
					</Text>
					<Text>Smart-Chain</Text>
				</View>
				<View style={styles.separator}></View>
				<Text style={styles.header}>
					<I18n map="pages.certificate.explorer"></I18n>
				</Text>
				<Link style={styles.link} src={props.explorerLink}>
					{props.explorerLink}
				</Link>
			</View>
		</Page>
	</DocumentPDF>
);
